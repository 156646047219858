import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useForm,
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormGetValues,
  useWatch,
} from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import { apiSlice } from "../../store/apiSlice";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import type { AmpUserDetail } from "../../types/user";
import { useNavigate, useParams } from "react-router-dom";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { ErrorMessage } from "../../components/global/ErrorMessage";
import Select from "react-select";
import ModalWrapper from "../../components/global/ModalWrapper";
import {
  mediaSchema,
  useMediaUpload,
  ImageCropper,
  singleMediaSchema,
  fetchFileData,
  fileToDataString,
} from "../../hooks/useMediaUploadFile";
import { AttractionSearch } from "../../components/form/AttractionsSearch";
import { hasUrl } from "../../helpers/hasUrl";
import { isValidURL } from "../../helpers/isValidUrl";
import { postcodeValidation } from "../../components/global/www/modals/SearchClaimModal";
import {
  GoogleMap,
  PostcodeLookupComponent,
} from "./attractions/AttractionLocation";
import { Button } from "../../components/global/Button";
import { useWindowResize } from "../../hooks/useWindowResize";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import RichTextEditor, {
  getCharLength,
  getWordLength,
} from "../../components/form/RichTextEditor";
import MultiSelectCategoryOptionsSm from "../../lib/react-select/MultiSelectCategoryOptionsSm";
import { DropDownIndicator } from "./attractions/AttractionOpeningTimes";
import { restartAnimation } from "../../components/global/amp/ToastNotification";
import { isSuccessResponse } from "../../helpers/rtkHelpers";

const categories: { value: string; label: string }[] = [
  { value: "3_and_under", label: "Babies and Toddlers" },
  { value: "younger_children", label: "Child" },
  { value: "adult", label: "Adult" },
];

const activeOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const eventTypeOptions = [
  {
    value: "premium",
    label: "Premium",
  },
  {
    value: "enhanced",
    label: "Enhanced",
  },
  {
    value: "standard",
    label: "Standard",
  },
];

const categoryOptions = [
  {
    value: "halloween",
    label: "Halloween",
  },
  {
    value: "christmas",
    label: "Christmas",
  },
];

const ages: { value: string; label: string }[] = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18+", label: "18+" },
];

const defaultPrice: any = {
  category: "3_and_under",
  age: {
    from: "0",
    to: "3",
  },
  price: null,
  isFree: false,
};
const highlightTitleSchema = z
  .string()
  .max(255, "Highlight is too long.")
  .min(1, { message: "Required" })
  .nullable()
  .superRefine((val, ctx) => {
    if (!val) return;
    if (val.trim().split(" ").length > 10) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Too long",
      });
    }
  });
const highlightDescriptionSchema = z
  .string()
  .nullable()
  .superRefine((value, ctx) => {
    if (hasUrl(value ?? "")) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `You are not allowed include a url.`,
      });
    }

    const wordLength = getWordLength(value ?? "");
    const maxCharCount = 200;

    if (wordLength > maxCharCount) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: ``,
      });
    }

    const charLen = getCharLength(value?.trim() ?? "");
    if (charLen === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
      });
    }
  });

const textareaValidationSchema = (words: number = 20) =>
  z
    .string()
    .min(1, { message: "Required" })
    .superRefine((value, ctx) => {
      if (hasUrl(value ?? "")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You are not allowed include a url.`,
        });
      }
      if (value.split(" ").length > words) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Too long.",
        });
      }
    });

const AddEvent: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const {
    data: getQuery,
    isSuccess: isQuerySuccess,
    isFetching,
    refetch,
  } = apiSlice.useGetEventQuery({ id: id ?? "" }, { skip: !isEdit || !id });

  const [uploadMedia] = apiSlice.useUploadImageToEventMutation();
  const [addEvent, { isLoading, isError, isSuccess, error }] =
    apiSlice.useAddEventMutation();
  const windowSize = useWindowResize();

  const schema = z.object({
    venue: z.string().nullable().optional(),
    venue_name: z.string().min(1, "Required"),
    title: z.string().min(1, "Required"),
    category: z.string(),
    validFrom: z.any(),
    validTo: z.any(),
    websiteAddress: z
      .string()
      .min(1, "Website address is required")
      .max(255, "Website is too long")
      .superRefine((value, ctx) => {
        if (!isValidURL(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Please enter the url with correct format (e.g. example.com)`,
          });
        }
      }),
    summary: z.string().superRefine((value, ctx) => {
      const charLen = getCharLength(value?.trim() ?? "");
      if (charLen === 0 || value.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
        });
      }

      if (hasUrl(value ?? "")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You are not allowed include a url.`,
        });
      }

      const wordLength = getWordLength(value ?? "");
      const maxCharCount = 1000;

      if (wordLength > maxCharCount) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: ``,
        });
      }
    }),
    notes: textareaValidationSchema(20),
    meta_description: textareaValidationSchema(20),
    seo_title: z.string(),
    openingTimes: z.string().superRefine((value, ctx) => {
      const charLen = getCharLength(value?.trim() ?? "");
      if (charLen === 0 || value.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
        });
      }

      if (hasUrl(value ?? "")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You are not allowed include a url.`,
        });
      }

      const wordLength = getWordLength(value ?? "");
      const maxCharCount = 1000;

      if (wordLength > maxCharCount) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: ``,
        });
      }
    }),

    highlight_1: highlightTitleSchema,
    highlight_2: highlightTitleSchema,
    highlight_3: highlightTitleSchema,
    highlight_1_description: highlightDescriptionSchema,
    highlight_2_description: highlightDescriptionSchema,
    highlight_3_description: highlightDescriptionSchema,
    highlight_1_image: singleMediaSchema.optional(),
    highlight_2_image: singleMediaSchema.optional(),
    highlight_3_image: singleMediaSchema.optional(),

    media: mediaSchema,

    address: z.string().min(1, { message: "Address is required." }),
    address2: z.string().nullable(),
    address3: z.string().nullable(),
    town: z.string(),
    county: z.string(),
    postcode: postcodeValidation,
    location: z
      .object({
        lat: z.number(),
        lng: z.number(),
      })
      .nullable()
      .optional(),
    prices: z
      .object({
        category: z.enum(categories.map((c) => c.value) as [string]),
        age: z.object({
          from: z.enum(["no_min", ...ages.map((a) => a.value)] as [string]),
          to: z.enum(["no_max", ...ages.map((a) => a.value)] as [string]),
        }),
        price: z.coerce
          .number({ invalid_type_error: "Please enter a number" })
          .min(0, { message: "Price can't be empty." })
          .max(9000, { message: "Please input a price less than 9000 pounds." })
          .nullable()
          .refine((v) => {
            return v !== null;
          })
          .transform((v) => {
            return v != null && Number.isInteger(v)
              ? parseInt(v.toString().split(".")[0])
              : v;
          }),
        isFree: z.boolean(),
        description: z.string(),
      })
      .superRefine((val, ctx) => {
        if ((val.price ?? 0) <= 0 && !val.isFree) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Enter a price or choose free.",
            path: ["price"],
          });
        }
      })
      .array(),

    active: z.enum(["yes", "no"]),
    visible: z.enum(["yes", "no"]),
    eventType: z.enum(["premium", "standard", "enhanced"]),
  });

  type AddAdPlacementForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    watch,
    control,
    getValues,
    setValue,
    reset,
  } = useForm<AddAdPlacementForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      validFrom: new Date().toISOString().split("T")?.[0]!,
      validTo: new Date().toISOString().split("T")?.[0]!,
    },
  });

  useEffect(() => {
    document.title = "Add Event | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Successful",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot add changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !(user as AmpUserDetail)?.email_verified &&
      (user as AmpUserDetail).role !== "ADMIN"
    ) {
      navigate("/dashboard");
    }
  }, []);

  const [getShowDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [croppingImageHighlight, setCroppingImageHighlight] = useState<
    1 | 2 | 3 | -1
  >(-1);
  const [croppingImage, setCroppingImage] = useState<number>();

  useEffect(() => {
    setCroppingImageHighlight(-1);
  }, [croppingImage]);
  function getHighLightCroppingImage(index: number) {
    if (index === 1) {
      return watchedHighlightImage1;
    } else if (index === 2) {
      return watchedHighlightImage2;
    }
    return watchedHighlightImage3;
  }

  const cropHighlightImage = (value: File) => {
    const index = (croppingImageHighlight || 1) as 1 | 2 | 3;
    const currentData = getValues(`highlight_${index}_image`);

    if (currentData) {
      setValue(`highlight_${index}_image`, {
        ...currentData,
        filename: currentData?.filename || "",
        size: currentData?.size || 0,
        url: value,
      });
    }

    setShowCropModal(false);
  };

  const [getShowCropModal, setShowCropModal] = useState<boolean>(false);

  const mediaControls = useFieldArray({
    control,
    name: "media",
    rules: {
      minLength: 0,
    },
  });
  const { fields, append } = mediaControls;
  const { createMedia, removeSingle, cancelCrop, cropImage } = useMediaUpload({
    getValues: getValues as unknown as UseFormGetValues<{
      media: z.infer<typeof mediaSchema>;
    }>,
    controls: mediaControls as unknown as UseFieldArrayReturn<
      {
        media: z.infer<typeof mediaSchema>;
      },
      "media",
      "id"
    >,
    croppingImage,
    setCroppingImage,
    setShowCropModal,
    setShowDeleteModal,
    setCustomCroppingImage: setCroppingImageHighlight,
  });

  const [uploadingMedia, setUploadingMedia] = useState(false);
  async function onSubmit() {
    const payload: CreateAmpEventsPayload = {
      id: isEdit ? id : undefined,
      venue_id: getValues("venue") ?? "",
      venue_name: getValues("venue_name") ?? "",
      title: getValues("title"),
      valid_from: getValues("validFrom")
        ? new Date(getValues("validFrom")).toISOString()
        : new Date().toISOString(),
      valid_to: getValues("validTo")
        ? new Date(getValues("validTo")).toISOString()
        : new Date().toISOString(),

      summary: getValues("summary"),
      meta_description: getValues("meta_description"),
      seo_title: getValues("seo_title"),
      category: getValues("category"),

      website: getValues("websiteAddress"),
      notes: getValues("notes"),
      opening_times: getValues("openingTimes"),
      highlight_1: getValues("highlight_1"),
      highlight_2: getValues("highlight_2"),
      highlight_3: getValues("highlight_3"),
      highlight_1_description: getValues("highlight_1_description"),
      highlight_2_description: getValues("highlight_2_description"),
      highlight_3_description: getValues("highlight_3_description"),
      address: getValues("address"),
      address2: getValues("address2"),
      address3: getValues("address3"),
      town: getValues("town"),
      county: getValues("county"),
      postcode: getValues("postcode"),
      location: getValues("location"),
      active: getValues("active") === "yes",
      visible: getValues("visible") === "yes",
      event_type: getValues("eventType"),
      prices: JSON.stringify(getValues("prices")),
    };

    for (let i = 0; i < getValues("media").length; i++) {
      const media = getValues("media")[i];
      const index = (i + 1) as 1 | 2 | 3 | 4;

      if (media.image_db_id) {
        payload[`media_${index}_id`] = media.image_db_id;
      } else {
        setUploadingMedia(true);
        const formData = new FormData();
        formData.append("file", media.url);
        const uploadedMedia = await uploadMedia({
          file: formData,
        });
        if (isSuccessResponse(uploadedMedia)) {
          payload[`media_${index}_id`] = uploadedMedia.data.id;
        } else {
          console.log("ERROR Uploading Media", uploadedMedia.error);
        }
        setUploadingMedia(false);
      }
    }

    if (getValues("highlight_1_image")) {
      const media = getValues("highlight_1_image");

      if (media) {
        if (media.image_db_id) {
          payload[`highlight_1_image`] = media.image_db_id;
        } else {
          setUploadingMedia(true);
          const formData = new FormData();
          formData.append("file", media.url);
          const uploadedMedia = await uploadMedia({
            file: formData,
          });
          if (isSuccessResponse(uploadedMedia)) {
            payload[`highlight_1_image`] = uploadedMedia.data.id;
          } else {
            console.log("ERROR Uploading Media", uploadedMedia.error);
          }
          setUploadingMedia(false);
        }
      }
    }

    if (getValues("highlight_2_image")) {
      const media = getValues("highlight_2_image");

      if (media) {
        if (media.image_db_id) {
          payload[`highlight_2_image`] = media.image_db_id;
        } else {
          setUploadingMedia(true);
          const formData = new FormData();
          formData.append("file", media.url);
          const uploadedMedia = await uploadMedia({
            file: formData,
          });
          if (isSuccessResponse(uploadedMedia)) {
            payload[`highlight_2_image`] = uploadedMedia.data.id;
          } else {
            console.log("ERROR Uploading Media", uploadedMedia.error);
          }
          setUploadingMedia(false);
        }
      }
    }

    if (getValues("highlight_3_image")) {
      const media = getValues("highlight_3_image");

      if (media) {
        if (media.image_db_id) {
          payload[`highlight_3_image`] = media.image_db_id;
        } else {
          setUploadingMedia(true);
          const formData = new FormData();
          formData.append("file", media.url);
          const uploadedMedia = await uploadMedia({
            file: formData,
          });
          if (isSuccessResponse(uploadedMedia)) {
            payload[`highlight_3_image`] = uploadedMedia.data.id;
          } else {
            console.log("ERROR Uploading Media", uploadedMedia.error);
          }
          setUploadingMedia(false);
        }
      }
    }

    const results = await addEvent(payload);

    if ("data" in results && !isEdit) {
      navigate(`/events/${results.data.short_id}`);
    }

    await refetch();
    restartAnimation();
  }

  useEffect(() => {
    if (getQuery && isQuerySuccess && !isFetching) {
      let media: (z.infer<typeof mediaSchema>[number] | undefined)[] = [];
      (async () => {
        media = await Promise.all(
          ([1, 2, 3, 4] as (1 | 2 | 3 | 4)[])?.map(async (index) => {
            const media = getQuery[`media_${index}`];
            if (!media) return;
            const data = await fetchFileData(media?.url);
            return {
              url: data.data,
              urlString: ((await fileToDataString(data.data)) || "") as string,
              filename: media.id,
              size: data.size,
              createdAt: new Date(),
              isPrimary: false,
              persisted: true,
              image_db_id: media.id,
            };
          }) ?? []
        );

        let highlightMedia1;
        let highlightMedia2;
        let highlightMedia3;

        const highlightMedia1Media = getQuery[`highlight_1_image_media`];
        if (highlightMedia1Media) {
          const highlightMedia1Data = await fetchFileData(
            highlightMedia1Media?.url
          );
          highlightMedia1 = {
            url: highlightMedia1Data.data,
            urlString: ((await fileToDataString(highlightMedia1Data.data)) ||
              "") as string,
            filename: highlightMedia1Media.id,
            size: highlightMedia1Data.size,
            createdAt: new Date(),
            isPrimary: false,
            persisted: true,
            image_db_id: highlightMedia1Media.id,
          };
        }

        const highlightMedia2Media = getQuery[`highlight_2_image_media`];
        if (highlightMedia2Media) {
          const highlightMedia2Data = await fetchFileData(
            highlightMedia2Media?.url
          );
          highlightMedia2 = {
            url: highlightMedia2Data.data,
            urlString: ((await fileToDataString(highlightMedia2Data.data)) ||
              "") as string,
            filename: highlightMedia2Media.id,
            size: highlightMedia2Data.size,
            createdAt: new Date(),
            isPrimary: false,
            persisted: true,
            image_db_id: highlightMedia2Media.id,
          };
        }

        const highlightMedia3Media = getQuery[`highlight_3_image_media`];
        if (highlightMedia3Media) {
          const highlightMedia3Data = await fetchFileData(
            highlightMedia3Media?.url
          );
          highlightMedia3 = {
            url: highlightMedia3Data.data,
            urlString: ((await fileToDataString(highlightMedia3Data.data)) ||
              "") as string,
            filename: highlightMedia3Media.id,
            size: highlightMedia3Data.size,
            createdAt: new Date(),
            isPrimary: false,
            persisted: true,
            image_db_id: highlightMedia3Media.id,
          };
        }
        reset(
          {
            venue: getQuery.venue_id,
            venue_name: getQuery.venue_name,
            title: getQuery.title,
            category: getQuery.category,
            websiteAddress: getQuery.website,
            summary: getQuery.summary,
            meta_description: getQuery.meta_description,
            seo_title: getQuery.seo_title,
            notes: getQuery.notes,
            openingTimes: getQuery.opening_times,
            highlight_1: getQuery.highlight_1,
            highlight_2: getQuery.highlight_2,
            highlight_3: getQuery.highlight_3,
            highlight_1_description: getQuery.highlight_1_description,
            highlight_2_description: getQuery.highlight_2_description,
            highlight_3_description: getQuery.highlight_3_description,
            highlight_1_image: getQuery[`highlight_1_image_media`]
              ? highlightMedia1
              : undefined,
            highlight_2_image: getQuery[`highlight_2_image_media`]
              ? highlightMedia2
              : undefined,
            highlight_3_image: getQuery[`highlight_3_image_media`]
              ? highlightMedia3
              : undefined,
            address: getQuery.address,
            address2: getQuery.address2,
            address3: getQuery.address3,
            town: getQuery.town,
            county: getQuery.county,
            postcode: getQuery.postcode,
            location: JSON.parse(getQuery.location as unknown as string),
            prices:
              typeof getQuery.prices === "string" &&
              getQuery.prices.startsWith("[")
                ? JSON.parse(getQuery.prices)
                : [],
            active: getQuery.active ? "yes" : "no",
            visible: getQuery.visible ? "yes" : "no",

            validFrom: getQuery.valid_from
              ? new Date(getQuery.valid_from).toISOString().split("T")?.[0]
              : "",
            validTo: getQuery.valid_to
              ? new Date(getQuery.valid_to).toISOString().split("T")?.[0]
              : "",
            eventType: getQuery.event_type,
            media: media.filter((media) => media),
          },
          {
            keepDirty: false,
          }
        );
      })();
    }
  }, [getQuery, isFetching]);

  const defaultAttractions = {
    label: getQuery?.venue?.name ?? "",
    value: getQuery?.venue_id ?? "",
    attraction: {
      id: (getQuery?.venue?.id as string) ?? "",
      image: "",
      image_alt: "",
      location: "",
      title: getQuery?.venue?.name ?? "",
    },
    media: getQuery?.venue?.media,
  };

  const [address, setAddress] = useState({
    line_1: "",
    line_2: "",
    line_3: "",
    post_town: "",
    county: "",
    postcode: "",
    latitude: 51.5266512,
    longitude: -0.0245508,
  });

  useEffect(() => {
    if (address.line_1) {
      setValue("address", address.line_1, { shouldDirty: true });
      setValue("address2", address.line_2, { shouldDirty: true });
      setValue("address3", address.line_3, { shouldDirty: true });
      setValue("town", address.post_town, { shouldDirty: true });
      setValue("county", address.county, { shouldDirty: true });
      setValue("postcode", address.postcode, { shouldDirty: true });
      setValue(
        "location",
        {
          lat: address.latitude,
          lng: address.longitude,
        },
        { shouldDirty: true }
      );
    }
  }, [address, setValue]);

  const {
    fields: prices,
    append: appendPrice,
    remove: removePrice,
  } = useFieldArray({
    control,
    name: "prices",
    rules: {
      minLength: 1,
    },
  });
  const getCategory = (value: string) => {
    return categories.find((c) => c.value === value);
  };
  const addPrice = () => {
    appendPrice(defaultPrice);
  };

  const changedCategory = (value: string, index: number) => {
    if (!fields[index]) return;

    switch (value) {
      case "3_and_under":
        setValue(`prices.${index}.age.from`, "0");
        setValue(`prices.${index}.age.to`, "3");
        break;
      case "younger_children":
        setValue(`prices.${index}.age.from`, "4");
        setValue(`prices.${index}.age.to`, "17");
        break;
      case "adult":
        setValue(`prices.${index}.age.from`, "17");
        setValue(`prices.${index}.age.to`, "18+");
        break;
    }
  };
  const getAge = (
    value: string,
    additional: { label: string; value: string }[] = []
  ) => {
    return [...additional, ...ages].find((c) => c.value === value);
  };

  const watchedHighlight1 = useWatch({ control, name: "highlight_1" });
  const watchedHighlight2 = useWatch({ control, name: "highlight_2" });
  const watchedHighlight3 = useWatch({ control, name: "highlight_3" });

  const watchedHighlightImage1 = useWatch({
    control,
    name: "highlight_1_image",
  });
  const watchedHighlightImage2 = useWatch({
    control,
    name: "highlight_2_image",
  });
  const watchedHighlightImage3 = useWatch({
    control,
    name: "highlight_3_image",
  });

  const watchedValidFrom = useWatch({ control, name: "validFrom" });
  const watchedValidTo = useWatch({ control, name: "validTo" });

  const [getAttraction] = apiSlice.useLazyGetAttractionQuery();
  async function updateLocationBasedOnVenue(short_id: string) {
    const attraction = await getAttraction(short_id);
    if (attraction.error) return;
    const attractionData = attraction.data?.result;
    attractionData?.name && setValue("venue_name", attractionData.name);
    attractionData?.address && setValue("address", attractionData.address);
    attractionData?.address2 && setValue("address2", attractionData.address2);
    attractionData?.address3 && setValue("address3", attractionData.address3);
    attractionData?.town?.name && setValue("town", attractionData.town?.name);
    attractionData?.postcode && setValue("postcode", attractionData.postcode);
    attractionData?.town?.county?.name &&
      setValue("county", attractionData.town?.county?.name);
    const location =
      attractionData?.location && typeof attractionData?.location === "string"
        ? JSON.parse(attractionData.location)
        : attractionData?.location || undefined;
    if (location) {
      setValue("location", {
        lat: location.coordinates[1],
        lng: location.coordinates[0],
      });
    }
  }

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <Header title={!isEdit ? "Add Event" : "Edit Event"} showBack />

      <SingleAttractionTemplate name={"Event"}>
        <form onSubmit={handleSubmit(onSubmit)} className="xl:mt-0 mt-[70px]">
          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 max-w-[570px] xl:w-3/5 gap-y-5">
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"title"}
              label="Title"
              required
              inputProps={{ ...register("title") }}
            />

            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"seo_title"}
              label="SEO Title"
              required
              inputProps={{ ...register("seo_title") }}
            />

            <TextInput
              className="w-full group"
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
              inputClassName="border-[1px] border-[#CFDBD5] text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
              control={control}
              name={"websiteAddress"}
              label="Website address"
              required
              inputProps={{ ...register("websiteAddress") }}
            />

            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex w-full focus:outline-cs-pink"
              control={control}
              name={"validFrom"}
              label="Start Date"
              required
              inputProps={{
                ...register("validFrom", {
                  setValueAs(value) {
                    if (value.length > 0) {
                      return new Date(value);
                    }
                    return "";
                  },
                }),
                value:
                  (watchedValidFrom instanceof Date
                    ? (watchedValidFrom as Date)?.toISOString()
                    : watchedValidFrom
                  )?.split("T")?.[0] ?? "",
                type: "date",
              }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex w-full focus:outline-cs-pink"
              control={control}
              name={"validTo"}
              label="End Date"
              required
              inputProps={{
                ...register("validTo", {
                  setValueAs(value) {
                    if (value.length > 0) {
                      return new Date(value);
                    }
                    return "";
                  },
                }),
                value:
                  (watchedValidTo instanceof Date
                    ? (watchedValidTo as Date)?.toISOString()
                    : watchedValidTo
                  )?.split("T")?.[0] ?? "",
                type: "date",
              }}
            />

            <div className={`flex flex-col w-full md:w-3/4 xl:w-full`}>
              <div className={`flex flex-col gap-2.5`}>
                <span className="flex items-center font-bold text-sm">
                  Description
                  <span className="text-cs-pink">*</span>
                </span>
              </div>
              <div className="relative flex flex-col gap-2.5">
                <div className="relative">
                  {(getQuery?.summary || !isEdit) && (
                    <RichTextEditor
                      charLabel={``}
                      isWordCount={true}
                      maxChars={1000}
                      maxCharSuffix={" words"}
                      initialValue={getQuery?.summary ?? ""}
                      onChange={(value: string) => setValue("summary", value)}
                      onDirty={() =>
                        setTimeout(() => {
                          setValue("summary", getValues("summary"), {
                            shouldDirty: true,
                          });
                        }, 100)
                      }
                      placeholder=""
                      label=""
                    />
                  )}
                </div>
              </div>

              {errors["summary"] && (
                <ErrorMessage>{errors["summary"].message}</ErrorMessage>
              )}
            </div>

            <div className={`flex flex-col w-full md:w-3/4 xl:w-full`}>
              <span className="flex items-center font-bold text-sm">
                Meta Description
              </span>
              <div className="relative flex flex-col gap-2.5">
                <Controller
                  control={control}
                  name={"meta_description"}
                  render={({ field: { ref, value, onChange } }) => (
                    <>
                      <textarea
                        value={value ?? ""}
                        onChange={onChange}
                        placeholder=""
                        className="text-cs-gray placeholder:text-sm text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40"
                      ></textarea>
                      <p className="text-[#5F646D] text-sm text-right w-full mt-2">
                        {(value?.split(" ")?.length ?? 0) + " / 20"} words
                      </p>
                    </>
                  )}
                />
              </div>

              {errors["meta_description"] && (
                <ErrorMessage>
                  {errors["meta_description"].message}
                </ErrorMessage>
              )}
            </div>

            <div className={`flex flex-col w-full mt-5`}>
              <div className="font-bold text-sm">Opening Times</div>

              <div className="relative">
                {(getQuery?.opening_times || !isEdit) && (
                  <RichTextEditor
                    charLabel={``}
                    isWordCount={true}
                    maxChars={1000}
                    maxCharSuffix={" words"}
                    initialValue={getQuery?.opening_times ?? ""}
                    onChange={(value: string) =>
                      setValue("openingTimes", value)
                    }
                    onDirty={() =>
                      setTimeout(() => {
                        setValue("openingTimes", getValues("openingTimes"), {
                          shouldDirty: true,
                        });
                      }, 100)
                    }
                    placeholder=""
                    label=""
                  />
                )}
              </div>

              {errors["openingTimes"] && (
                <ErrorMessage>{errors["openingTimes"]?.message}</ErrorMessage>
              )}
            </div>

            <div className={`flex flex-col w-full`}>
              <div className="font-bold text-sm">Admission Note</div>
              <div className="relative flex flex-col gap-2.5">
                <Controller
                  control={control}
                  name={"notes"}
                  render={({ field: { ref, value, onChange } }) => (
                    <>
                      <textarea
                        value={value ?? ""}
                        onChange={onChange}
                        placeholder=""
                        className="text-cs-gray placeholder:text-sm text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40"
                      ></textarea>
                      <p className="text-[#5F646D] text-sm text-right w-full mt-2">
                        {(value?.split(" ")?.length ?? 0) + " / 20"} words
                      </p>
                    </>
                  )}
                />
              </div>

              {errors["notes"] && (
                <ErrorMessage>{errors["notes"].message}</ErrorMessage>
              )}
            </div>

            <div>
              <div className="flex items-center font-bold text-sm mb-2">
                Active
              </div>
              <Controller
                control={control}
                name="active"
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      onChange={(value, meta) => {
                        onChange(value?.value ?? null);
                      }}
                      value={activeOptions.find((v) => v.value === value)}
                      defaultValue={null}
                      isClearable={false}
                      closeMenuOnSelect={false}
                      menuIsOpen={true}
                      hideSelectedOptions={false}
                      options={activeOptions}
                      components={{ Option: MultiSelectCategoryOptionsSm }}
                      placeholder={""}
                      styles={{
                        multiValue: (styles) => ({
                          ...styles,
                          backgroundColor: "#6836D1",
                          borderRadius: 20,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                        }),
                        multiValueRemove: () => ({
                          display: "none",
                        }),
                        multiValueLabel: (styles) => ({
                          ...styles,
                          color: "#FFF",
                          paddingLeft: 10,
                          paddingRight: 10,
                          fontSize: 14,
                          fontWeight: 400,
                        }),
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          paddingLeft: 5,
                          border: "0",
                          overflow: "hidden",
                          boxShadow: "none",
                          display: "flex",
                          width: "100%",
                          position: "relative",
                        }),
                        menuList: (base) => ({
                          ...base,
                          marginBottom: 0,
                          overflow: "hidden",
                          display: "flex",
                          gap: 12,
                        }),
                        option: (base) => ({
                          ...base,
                          background: "red",
                          padding: "0",
                        }),
                        noOptionsMessage: (base) => ({
                          ...base,
                          display: "none",
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          display: "none",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        background: "none",
                        colors: {
                          ...theme.colors,
                          neutral80: "#555",
                        },
                      })}
                    />
                    {error && <ErrorMessage>{error.message}</ErrorMessage>}
                  </>
                )}
              />
            </div>

            <div>
              <div className="flex items-center font-bold text-sm mb-2">
                Visible
              </div>
              <Controller
                control={control}
                name="visible"
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      onChange={(value, meta) => {
                        onChange(value?.value ?? null);
                      }}
                      value={activeOptions.find((v) => v.value === value)}
                      defaultValue={null}
                      isClearable={false}
                      closeMenuOnSelect={false}
                      menuIsOpen={true}
                      hideSelectedOptions={false}
                      options={activeOptions}
                      components={{ Option: MultiSelectCategoryOptionsSm }}
                      placeholder={""}
                      styles={{
                        multiValue: (styles) => ({
                          ...styles,
                          backgroundColor: "#6836D1",
                          borderRadius: 20,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                        }),
                        multiValueRemove: () => ({
                          display: "none",
                        }),
                        multiValueLabel: (styles) => ({
                          ...styles,
                          color: "#FFF",
                          paddingLeft: 10,
                          paddingRight: 10,
                          fontSize: 14,
                          fontWeight: 400,
                        }),
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          paddingLeft: 5,
                          border: "0",
                          overflow: "hidden",
                          boxShadow: "none",
                          display: "flex",
                          width: "100%",
                          position: "relative",
                        }),
                        menuList: (base) => ({
                          ...base,
                          marginBottom: 0,
                          overflow: "hidden",
                          display: "flex",
                          gap: 12,
                        }),
                        option: (base) => ({
                          ...base,
                          background: "red",
                          padding: "0",
                        }),
                        noOptionsMessage: (base) => ({
                          ...base,
                          display: "none",
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          display: "none",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        background: "none",
                        colors: {
                          ...theme.colors,
                          neutral80: "#555",
                        },
                      })}
                    />
                    {error && <ErrorMessage>{error.message}</ErrorMessage>}
                  </>
                )}
              />
            </div>

            <div className="relative">
              <div className="font-bold text-sm mb-2">Event Type</div>

              <Controller
                control={control}
                name={`eventType`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      components={{
                        Option: SingleSelectOption,
                        DropdownIndicator: DropDownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      value={eventTypeOptions.find((v) => v.value === value)}
                      options={eventTypeOptions}
                      isSearchable={false}
                      styles={{
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          border: "none",
                          zIndex: 15,
                        }),
                        menuList: (base, isSelected) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                          background: "white",

                          "::-webkit-scrollbar": {
                            width: 14,
                          },
                          "::-webkit-scrollbar-thumb": {
                            background: "#c2c2c2",
                            borderRadius: 10,
                            border: "4px solid white",
                          },
                        }),
                        option: (
                          base,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...base,
                            background: "white",
                            border: "1px solid #c2c2c2",
                            color: isSelected ? "white" : "#555",
                            "&:hover": {
                              backgroundColor: "#6836D1",
                              color: isSelected || isFocused ? "white" : "#555",
                              border: isFocused ? "1px solid white" : "",
                            },
                            "&:active": {
                              backgroundColor: "#6836D1",
                              color: "white",
                            },
                          };
                        },
                        singleValue: (base) => ({
                          ...base,
                          color: "#555",
                          fontSize: 14,
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          cursor: "pointer",
                          outline: "none",
                          border: state.isFocused
                            ? "1px solid #CED3CF"
                            : `1px solid ${error ? "red" : "#CED3CF"}`,
                          "&:hover": {
                            border: state.isFocused
                              ? "1px solid #CED3CF"
                              : "1px solid #CED3CF",
                          },
                          boxShadow: "none",
                          paddingTop: 3,
                          paddingBottom: 3,
                          margin: 0,
                          minHeight: 40,
                          height: 40,
                          width: 168,
                          borderRadius: 8,
                          paddingLeft: 12,
                        }),
                      }}
                      onChange={(v) => {
                        onChange(v?.value!);
                      }}
                    />
                  </>
                )}
              />
            </div>

            <div className="relative">
              <div className="font-bold text-sm mb-2">Category</div>

              <Controller
                control={control}
                name={`category`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      components={{
                        Option: SingleSelectOption,
                        DropdownIndicator: DropDownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      value={categoryOptions.find((v) => v.value === value)}
                      options={categoryOptions}
                      isSearchable={false}
                      styles={{
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          border: "none",
                          zIndex: 15,
                        }),
                        menuList: (base, isSelected) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                          background: "white",

                          "::-webkit-scrollbar": {
                            width: 14,
                          },
                          "::-webkit-scrollbar-thumb": {
                            background: "#c2c2c2",
                            borderRadius: 10,
                            border: "4px solid white",
                          },
                        }),
                        option: (
                          base,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...base,
                            background: "white",
                            border: "1px solid #c2c2c2",
                            color: isSelected ? "white" : "#555",
                            "&:hover": {
                              backgroundColor: "#6836D1",
                              color: isSelected || isFocused ? "white" : "#555",
                              border: isFocused ? "1px solid white" : "",
                            },
                            "&:active": {
                              backgroundColor: "#6836D1",
                              color: "white",
                            },
                          };
                        },
                        singleValue: (base) => ({
                          ...base,
                          color: "#555",
                          fontSize: 14,
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          cursor: "pointer",
                          outline: "none",
                          border: state.isFocused
                            ? "1px solid #CED3CF"
                            : `1px solid ${error ? "red" : "#CED3CF"}`,
                          "&:hover": {
                            border: state.isFocused
                              ? "1px solid #CED3CF"
                              : "1px solid #CED3CF",
                          },
                          boxShadow: "none",
                          paddingTop: 3,
                          paddingBottom: 3,
                          margin: 0,
                          minHeight: 40,
                          height: 40,
                          width: 168,
                          borderRadius: 8,
                          paddingLeft: 12,
                        }),
                      }}
                      onChange={(v) => {
                        onChange(v?.value!);
                      }}
                    />
                  </>
                )}
              />
            </div>
          </fieldset>

          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 xl:w-full max-w-[970px] gap-y-5">
            <legend className="mb-4 text-[22px] font-bold text-black xl:whitespace-nowrap">
              Location
            </legend>
            <div className="max-w-[570px]">
              <label className="text-black text-sm font-bold">Venue</label>
              <AttractionSearch
                value={watch("venue") ?? ""}
                defaultOptions={defaultAttractions}
                onChange={(value) => setValue("venue", value)}
                onVenueSelected={(venue) =>
                  updateLocationBasedOnVenue(venue.short_id)
                }
              />
              {errors["venue"]?.message && (
                <ErrorMessage>{errors["venue"]?.message}</ErrorMessage>
              )}

              <TextInput
                name={"venue_name"}
                label="Venue Name"
                inputProps={{ ...register("venue_name") }}
                className="w-full mt-3 group"
                labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 max-w-[90%]">
              <div>
                <div>
                  <PostcodeLookupComponent
                    onAddressSelected={(address) => setAddress(address)}
                    inputClass="border border-[#CFDBD5] text-black rounded-lg py-3 px-4 h-9 focus:outline-cs-pink border-[#CFDBD5] hover:border-cs-pink w-full sm:w-auto mt-2"
                    buttonClass="rounded-lg bg-[#F2F2F2] h-9 py-2.5 px-4 mt-3 text-black text-sm font-bold w-full sm:w-auto ml-0 sm:ml-2 leading-[16px]"
                    selectClass="custom-select bg-white border-[1px] border-[#CED3CF] text-sm text-[#212121] rounded-lg py-[0.58rem] px-4 focus:outline-cs-pink hover:border-cs-pink mt-5 w-full md:w-3/4 xl:w-1/2"
                    messageClass="text-cs-red mt-3"
                    placeholder="Enter your postcode"
                  />

                  <div className={"grid grid-cols-1"}>
                    <div>
                      <TextInput
                        name={"address"}
                        label="Address first line"
                        inputProps={{ ...register("address") }}
                        className="w-full mt-3 group"
                        labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                        inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                        control={control}
                      />
                      <TextInput
                        name={"address2"}
                        label="Address second line (optional)"
                        inputProps={{ ...register("address2") }}
                        className="w-full mt-3 group"
                        labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                        inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                        control={control}
                      />
                      <TextInput
                        name={"address3"}
                        label="Address third line (optional)"
                        inputProps={{ ...register("address3") }}
                        className="w-full mt-3 group"
                        labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                        inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <TextInput
                    name={"town"}
                    label="City or Town"
                    inputProps={{ ...register("town") }}
                    className="w-full mt-3 group"
                    labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                    inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                    control={control}
                  />
                  <TextInput
                    name={"county"}
                    label="County"
                    inputProps={{ ...register("county") }}
                    className="w-full mt-3 group"
                    labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                    inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                    control={control}
                  />
                  <TextInput
                    name={"postcode"}
                    label="Postcode"
                    inputProps={{ ...register("postcode") }}
                    className="w-full mt-3 group"
                    labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                    inputClassName="h-9 border border-[#CFDBD5] text-cs-gray text-sm rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                    control={control}
                  />
                </div>
              </div>

              <div className={"grid grid-cols-1"}>
                <div>
                  <legend className="text-[#212121] font-bold">Map</legend>

                  <p className="text-[#5F646D] font-normal text-sm my-3">
                    Move the location pin directly to your attraction’s address.
                  </p>

                  <div className={"w-full h-[377px]"}>
                    <Controller
                      control={control}
                      name={"location"}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <GoogleMap
                          marker={{
                            lat:
                              value?.lat ??
                              // result?.town?.location?.coordinates?.[1] ??
                              51.50703296721856,
                            lng:
                              value?.lng ??
                              // result?.town?.location?.coordinates?.[0] ??
                              -0.12325286865234375,
                          }}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 xl:w-3/5 max-w-[570px] gap-y-5">
            <div>
              <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                <span>1/3 Highlight Title (max. 10 words) </span>

                <span className="text-sm text-cs-gray font-normal">
                  {watchedHighlight1
                    ?.trim()
                    ?.split(" ")
                    .filter((s) => s).length ?? 0}
                  /10 words
                </span>
              </h4>
              <div className="relative">
                <TextInput
                  className="group mt-1 w-full"
                  labelClassName="group-focus-within:text-cs-pink text-xs font-normal"
                  inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                  control={control}
                  name={"highlight_1"}
                  placeholder={""}
                  label=""
                  inputProps={{
                    ...register("highlight_1", {
                      maxLength: 255,
                    }),
                  }}
                />
              </div>
              <div className={`mt-4`}>
                <h4
                  className={`text-black font-bold text-sm mt-5 ${
                    user?.role === "ADMIN" ? "mb-[-25px]" : ""
                  }`}
                >
                  1/3 Highlight Description (max. 200 words){" "}
                </h4>
                {(getQuery?.highlight_1_description || !isEdit) && (
                  <RichTextEditor
                    maxChars={200}
                    maxCharSuffix={" words"}
                    isWordCount={true}
                    initialValue={getQuery?.highlight_1_description ?? ""}
                    onChange={(value: string) =>
                      setValue("highlight_1_description", value)
                    }
                    onDirty={() =>
                      setTimeout(() => {
                        setValue(
                          "highlight_1_description",
                          getValues("highlight_1_description"),
                          { shouldDirty: true }
                        );
                      }, 100)
                    }
                    placeholder="Ensure you emphasise what sets your Unique Selling Point apart. Everyone says that they have the best attraction in the UK, what unique aspect truly distinguishes your attraction and makes it the best?"
                    label=""
                  />
                )}
                {errors["highlight_1_description"]?.message && (
                  <ErrorMessage>
                    {errors["highlight_1_description"]?.message}
                  </ErrorMessage>
                )}
              </div>

              <div className="mt-4">
                {watchedHighlightImage1 ? (
                  <Media
                    className="w-full xl:w-1/2"
                    index={0}
                    size={watchedHighlightImage1.size}
                    createdAt={watchedHighlightImage1.createdAt}
                    url={watchedHighlightImage1.url}
                    isPrimary={false}
                    filename={watchedHighlightImage1.filename}
                    checked={false}
                    togglePrimary={() => {}}
                    remove={() => setValue("highlight_1_image", undefined)}
                    toggleSelect={() => {}}
                  />
                ) : (
                  <MediaUploadArea
                    uploadable={true}
                    createMedia={(event) =>
                      createMedia(
                        event,
                        (newValue: any) => {
                          return setValue("highlight_1_image", newValue);
                        },
                        1
                      )
                    }
                    fullSize={true}
                  />
                )}
              </div>
            </div>

            <div>
              <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                <span>2/3 Highlight Title (max. 10 words) </span>

                <span className="text-sm text-cs-gray font-normal">
                  {watchedHighlight2
                    ?.trim()
                    ?.split(" ")
                    .filter((s) => s).length ?? 0}
                  /10 words
                </span>
              </h4>
              <div className="relative">
                <TextInput
                  className="group mt-1 w-full"
                  labelClassName="group-focus-within:text-cs-pink text-xs font-normal"
                  inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                  control={control}
                  name={"highlight_2"}
                  placeholder={""}
                  label=""
                  inputProps={{
                    ...register("highlight_2", {
                      maxLength: 255,
                    }),
                  }}
                />
              </div>
              <div className={`mt-4`}>
                <h4
                  className={`text-black font-bold text-sm mt-5 ${
                    user?.role === "ADMIN" ? "mb-[-25px]" : ""
                  }`}
                >
                  2/3 Highlight Description (max. 200 words){" "}
                </h4>
                {(getQuery?.highlight_2_description || !isEdit) && (
                  <RichTextEditor
                    maxChars={200}
                    maxCharSuffix={" words"}
                    isWordCount={true}
                    initialValue={getQuery?.highlight_2_description ?? ""}
                    onChange={(value: string) =>
                      setValue("highlight_2_description", value)
                    }
                    onDirty={() =>
                      setTimeout(() => {
                        setValue(
                          "highlight_2_description",
                          getValues("highlight_2_description"),
                          { shouldDirty: true }
                        );
                      }, 100)
                    }
                    placeholder="Ensure you emphasise what sets your Unique Selling Point apart. Everyone says that they have the best attraction in the UK, what unique aspect truly distinguishes your attraction and makes it the best?"
                    label=""
                  />
                )}
                {errors["highlight_2_description"]?.message && (
                  <ErrorMessage>
                    {errors["highlight_2_description"]?.message}
                  </ErrorMessage>
                )}
              </div>

              <div className="mt-4">
                {watchedHighlightImage2 ? (
                  <Media
                    className="w-full xl:w-1/2"
                    index={0}
                    size={watchedHighlightImage2.size}
                    createdAt={watchedHighlightImage2.createdAt}
                    url={watchedHighlightImage2.url}
                    isPrimary={false}
                    filename={watchedHighlightImage2.filename}
                    checked={false}
                    togglePrimary={() => {}}
                    remove={() => setValue("highlight_2_image", undefined)}
                    toggleSelect={() => {}}
                  />
                ) : (
                  <MediaUploadArea
                    uploadable={true}
                    createMedia={(event) =>
                      createMedia(
                        event,
                        (newValue: any) => {
                          return setValue("highlight_2_image", newValue);
                        },
                        2
                      )
                    }
                    fullSize={true}
                  />
                )}
              </div>
            </div>

            <div>
              <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                <span>3/3 Highlight Title (max. 10 words) </span>

                <span className="text-sm text-cs-gray font-normal">
                  {watchedHighlight3
                    ?.trim()
                    ?.split(" ")
                    .filter((s) => s).length ?? 0}
                  /10 words
                </span>
              </h4>
              <div className="relative">
                <TextInput
                  className="group mt-1 w-full"
                  labelClassName="group-focus-within:text-cs-pink text-xs font-normal"
                  inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                  control={control}
                  name={"highlight_3"}
                  placeholder={""}
                  label=""
                  inputProps={{
                    ...register("highlight_3", {
                      maxLength: 255,
                    }),
                  }}
                />
              </div>
              <div className={`mt-4`}>
                <h4
                  className={`text-black font-bold text-sm mt-5 ${
                    user?.role === "ADMIN" ? "mb-[-25px]" : ""
                  }`}
                >
                  3/3 Highlight Description (max. 200 words){" "}
                </h4>
                {(getQuery?.highlight_3_description || !isEdit) && (
                  <RichTextEditor
                    maxChars={200}
                    maxCharSuffix={" words"}
                    isWordCount={true}
                    initialValue={getQuery?.highlight_3_description ?? ""}
                    onChange={(value: string) =>
                      setValue("highlight_3_description", value)
                    }
                    onDirty={() =>
                      setTimeout(() => {
                        setValue(
                          "highlight_3_description",
                          getValues("highlight_3_description"),
                          { shouldDirty: true }
                        );
                      }, 100)
                    }
                    placeholder="Ensure you emphasise what sets your Unique Selling Point apart. Everyone says that they have the best attraction in the UK, what unique aspect truly distinguishes your attraction and makes it the best?"
                    label=""
                  />
                )}
                {errors["highlight_3_description"]?.message && (
                  <ErrorMessage>
                    {errors["highlight_3_description"]?.message}
                  </ErrorMessage>
                )}
              </div>

              <div className="mt-4">
                {watchedHighlightImage3 ? (
                  <Media
                    className="w-full xl:w-1/2"
                    index={0}
                    size={watchedHighlightImage3.size}
                    createdAt={watchedHighlightImage3.createdAt}
                    url={watchedHighlightImage3.url}
                    isPrimary={false}
                    filename={watchedHighlightImage3.filename}
                    checked={false}
                    togglePrimary={() => {}}
                    remove={() => setValue("highlight_3_image", undefined)}
                    toggleSelect={() => {}}
                  />
                ) : (
                  <MediaUploadArea
                    uploadable={true}
                    createMedia={(event) =>
                      createMedia(
                        event,
                        (newValue: any) => {
                          return setValue("highlight_3_image", newValue);
                        },
                        3
                      )
                    }
                    fullSize={true}
                  />
                )}
              </div>
            </div>
          </fieldset>
          <fieldset className="mt-5">
            <legend className="mb-4 text-[22px] font-bold text-black xl:whitespace-nowrap">
              Prices
            </legend>

            <div className="inline-flex flex-col flex-wrap w-full mb-4 md:w-3/5 xl:w-full max-w-[550px]">
              {prices.map((field, index) => (
                <div
                  key={field.id}
                  className={`flex flex-col md:flex-row gap-2 mb-2`}
                >
                  <div>
                    {(index === 0 || windowSize?.width! <= 768) && (
                      <label className="flex mt-4 mb-3 text-sm font-normal text-[#5F646D]">
                        Category
                      </label>
                    )}

                    <Controller
                      control={control}
                      name={`prices.${index}.category`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <Select
                          defaultValue={
                            watch("prices")?.[index]?.category
                              ? getCategory(watch("prices")[index].category)
                              : categories[0]
                          }
                          components={{
                            Option: SingleSelectOption,
                            DropdownIndicator: () => (
                              <div className="px-2.5">
                                <svg
                                  width="10"
                                  height="6"
                                  viewBox="0 0 10 6"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>
                            ),
                            IndicatorSeparator: () => null,
                          }}
                          options={categories}
                          isSearchable={true}
                          styles={{
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              border: "none",
                            }),
                            menuList: (base, isSelected) => ({
                              ...base,
                              paddingTop: 0,
                              paddingBottom: 0,
                              background: "white",

                              "::-webkit-scrollbar": {
                                width: 14,
                              },
                              "::-webkit-scrollbar-thumb": {
                                background: "#c2c2c2",
                                borderRadius: 10,
                                border: "4px solid white",
                              },
                            }),
                            option: (
                              base,
                              { data, isDisabled, isFocused, isSelected }
                            ) => {
                              return {
                                ...base,
                                background: "white",
                                border: "1px solid #c2c2c2",
                                color: isSelected ? "white" : "#555",
                                "&:hover": {
                                  backgroundColor: "#F1F1F1",
                                  color:
                                    isSelected || isFocused ? "#000" : "#555",
                                  border: isFocused ? "1px solid white" : "",
                                },
                                "&:active": {
                                  backgroundColor: "#6836D1",
                                  color: "#000",
                                },
                              };
                            },
                            singleValue: (base) => ({
                              ...base,
                              color: "#555",
                              fontSize: 14,
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              cursor: "pointer",
                              outline: "none",
                              border: state.isFocused
                                ? "1px solid #CED3CF"
                                : `1px solid ${error ? "red" : "#CED3CF"}`,
                              "&:hover": {
                                border: state.isFocused
                                  ? "1px solid #CED3CF"
                                  : "1px solid #CED3CF",
                              },
                              boxShadow: "none",
                              paddingTop: 3,
                              paddingBottom: 3,
                              margin: 0,
                              minHeight: 40,
                              height: 40,
                              width: 188,
                              borderRadius: 8,
                            }),
                            valueContainer: (styles) => ({
                              ...styles,
                              paddingRight: 20,
                              paddingLeft: 20,
                              fontSize: 14,
                            }),
                          }}
                          onChange={(v) => {
                            const options = categories.map((c) => c.value) as [
                              string
                            ];
                            const value = options.includes(v?.value! as string)
                              ? v?.value!
                              : options[0];

                            onChange(value);
                            changedCategory(value, index);
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    {(index === 0 || windowSize?.width! <= 768) && (
                      <label className="flex mt-4 mb-3 text-sm font-normal text-[#5F646D]">
                        Age range
                      </label>
                    )}

                    <div className="flex gap-2.5">
                      <div className="min-w-[85px] relative">
                        <Controller
                          control={control}
                          name={`prices.${index}.age.from`}
                          render={({
                            field: { ref, value, onChange },
                            fieldState: { error },
                          }) => (
                            <Select
                              defaultValue={
                                watch("prices")?.[index]?.age?.from
                                  ? getAge(watch("prices")[index].age.from, [
                                      { label: "No min", value: "no_min" },
                                    ])
                                  : ages[0]
                              }
                              components={{
                                Option: SingleSelectOption,
                                DropdownIndicator: () => (
                                  <div className="px-2.5">
                                    <svg
                                      width="10"
                                      height="6"
                                      viewBox="0 0 10 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                ),
                                IndicatorSeparator: () => null,
                              }}
                              value={getAge(value, [
                                { label: "No min", value: "no_min" },
                              ])}
                              options={[
                                { label: "No min", value: "no_min" },
                                ...ages,
                              ]}
                              isSearchable={true}
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  margin: 0,
                                  border: "none",
                                  zIndex: 15,
                                }),
                                menuList: (base, isSelected) => ({
                                  ...base,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  background: "white",

                                  "::-webkit-scrollbar": {
                                    width: 14,
                                  },
                                  "::-webkit-scrollbar-thumb": {
                                    background: "#c2c2c2",
                                    borderRadius: 10,
                                    border: "4px solid white",
                                  },
                                }),
                                option: (
                                  base,
                                  { data, isDisabled, isFocused, isSelected }
                                ) => {
                                  return {
                                    ...base,
                                    background: "white",
                                    border: "1px solid #c2c2c2",
                                    color: isSelected ? "white" : "#555",
                                    "&:hover": {
                                      backgroundColor: "#F1F1F1",
                                      color:
                                        isSelected || isFocused
                                          ? "#000"
                                          : "#555",
                                      border: isFocused
                                        ? "1px solid white"
                                        : "",
                                    },
                                    "&:active": {
                                      backgroundColor: "#6836D1",
                                      color: "#000",
                                    },
                                  };
                                },
                                singleValue: (base) => ({
                                  ...base,
                                  color: "#555",
                                  fontSize: 14,
                                }),
                                control: (styles, state) => ({
                                  ...styles,
                                  cursor: "pointer",
                                  outline: "none",
                                  border: state.isFocused
                                    ? "1px solid #CED3CF"
                                    : `1px solid ${error ? "red" : "#CED3CF"}`,
                                  "&:hover": {
                                    border: state.isFocused
                                      ? "1px solid #CED3CF"
                                      : "1px solid #CED3CF",
                                  },
                                  boxShadow: "none",
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                  margin: 0,
                                  minHeight: 40,
                                  height: 40,
                                  width: 105,
                                  borderRadius: 8,
                                }),
                              }}
                              onChange={(v) => onChange(v?.value)}
                            />
                          )}
                        />
                      </div>
                      <div className="min-w-[85px] relative">
                        <Controller
                          control={control}
                          name={`prices.${index}.age.to`}
                          render={({
                            field: { ref, value, onChange },
                            fieldState: { error },
                          }) => (
                            <Select
                              defaultValue={
                                watch("prices")?.[index]?.age?.to
                                  ? getAge(watch("prices")[index].age.to, [
                                      { label: "No max", value: "no_max" },
                                    ])
                                  : ages[0]
                              }
                              components={{
                                Option: SingleSelectOption,
                                DropdownIndicator: () => (
                                  <div className="px-2.5">
                                    <svg
                                      width="10"
                                      height="6"
                                      viewBox="0 0 10 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                ),
                                IndicatorSeparator: () => null,
                              }}
                              value={getAge(value, [
                                { label: "No max", value: "no_max" },
                              ])}
                              options={[
                                { label: "No max", value: "no_max" },
                                ...ages,
                              ]}
                              isSearchable={true}
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  margin: 0,
                                  border: "none",
                                  zIndex: 15,
                                }),
                                menuList: (base, isSelected) => ({
                                  ...base,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  background: "white",

                                  "::-webkit-scrollbar": {
                                    width: 14,
                                  },
                                  "::-webkit-scrollbar-thumb": {
                                    background: "#c2c2c2",
                                    borderRadius: 10,
                                    border: "4px solid white",
                                  },
                                }),
                                option: (
                                  base,
                                  { data, isDisabled, isFocused, isSelected }
                                ) => {
                                  return {
                                    ...base,
                                    background: "white",
                                    border: "1px solid #c2c2c2",
                                    color: isSelected ? "#000" : "#555",
                                    "&:hover": {
                                      backgroundColor: "#F1F1F1",
                                      color:
                                        isSelected || isFocused
                                          ? "000"
                                          : "#555",
                                      border: isFocused
                                        ? "1px solid white"
                                        : "",
                                    },
                                    "&:active": {
                                      backgroundColor: "#6836D1",
                                      color: "#000",
                                    },
                                  };
                                },
                                singleValue: (base) => ({
                                  ...base,
                                  color: "#555",
                                  fontSize: 14,
                                }),
                                control: (styles, state) => ({
                                  ...styles,
                                  cursor: "pointer",
                                  outline: "none",
                                  border: state.isFocused
                                    ? "1px solid #CED3CF"
                                    : `1px solid ${error ? "red" : "#CED3CF"}`,
                                  "&:hover": {
                                    border: state.isFocused
                                      ? "1px solid #CED3CF"
                                      : "1px solid #CED3CF",
                                  },
                                  boxShadow: "none",
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                  margin: 0,
                                  minHeight: 40,
                                  height: 40,
                                  width: 105,
                                  borderRadius: 8,
                                }),
                              }}
                              onChange={(v) => onChange(v?.value)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-[118px] relative">
                    <label
                      className={`text-black font-normal text-md absolute left-4 z-[5] ${
                        index === 0 || windowSize?.width! <= 768
                          ? "top-[57px]"
                          : "top-[9px]"
                      }`}
                    >
                      £
                    </label>
                    <TextInput
                      className={`w-full group ${
                        (index === 0 || windowSize?.width! <= 768) && "mt-4"
                      }`}
                      labelClassName="group-focus-within:text-cs-pink !text-[#5F646D] text-sm font-normal"
                      inputClassName="border-cs-1 text-xs border-cs-bright-lt-gray text-black rounded-lg py-3 pr-4 pl-8 flex flex-col w-full focus:outline-cs-pink h-10"
                      errorClassName="text-sm [&>.w-5]:hidden"
                      control={control}
                      name={`prices.${index}.price`}
                      label={
                        index === 0 || windowSize?.width! <= 768 ? "Price" : ""
                      }
                      required
                      inputProps={{
                        ...register(`prices.${index}.price`, {
                          setValueAs: (v) =>
                            v === null || Number.isNaN(parseFloat(v))
                              ? null
                              : Number.isInteger(v)
                              ? v
                              : parseFloat(v).toFixed(2),
                          min: 0,
                          max: 9000,
                          async onBlur(e) {
                            new Promise((res) => setTimeout(res, 10));
                            if (e.target.value === 0) {
                              setValue(`prices.${index}.isFree`, true);
                            }
                          },
                        }),
                        placeholder: "Enter price",
                        type: "string",
                      }}
                      readonly={getValues(`prices.${index}.isFree`) === true}
                    />
                  </div>

                  <div
                    className={`flex items-center text-sm font-normal checkbox-item primary-checkmark text-cs-gray w-[70px] whitespace-nowrap ${
                      index === 0
                        ? "md:mt-[60px] mt-2.5 md:mb-0 mb-2.5"
                        : "mt-2.5 md:mb-0 mb-2.5"
                    }`}
                  >
                    <span className="text-xs font-normal text-[#212121]">
                      Free
                    </span>
                    <Controller
                      control={control}
                      name={`prices.${index}.isFree`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <input
                          ref={ref}
                          className="!w-20"
                          type="checkbox"
                          checked={value}
                          onChange={(value) => {
                            if (value.target.checked) {
                              setValue(`prices.${index}.price`, 0);
                            }
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    <span className="checkmark radio-checkmark border-cs-bright-lt-gray"></span>
                  </div>

                  <div>
                    <TextInput
                      className={`w-full group ${
                        (index === 0 || windowSize?.width! <= 768) && "mt-4"
                      }`}
                      labelClassName="group-focus-within:text-cs-pink !text-[#5F646D] text-sm font-normal"
                      inputClassName="border-cs-1 text-xs border-cs-bright-lt-gray text-black rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink h-10"
                      errorClassName="text-sm [&>.w-5]:hidden"
                      control={control}
                      name={`prices.${index}.description`}
                      label={
                        index === 0 || windowSize?.width! <= 768
                          ? "Description"
                          : ""
                      }
                      required
                      inputProps={{
                        ...register(`prices.${index}.description`),
                        type: "string",
                      }}
                    />
                  </div>

                  <button
                    className={`w-7 ${
                      index === 0 && fields.length === 1
                        ? "md:opacity-0 md:pointer-events-none"
                        : ""
                    }`}
                    type="button"
                    onClick={() => removePrice(index)}
                  >
                    <svg
                      className={`${index === 0 ? "mt-0 md:mt-10" : ""}`}
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_14_12572)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.90625 4.20508V2.14258H14.0938V4.20508H7.90625ZM5.84375 4.20508V1.45508C5.84375 1.09041 5.98862 0.740669 6.24648 0.482806C6.50434 0.224944 6.85408 0.0800781 7.21875 0.0800781L14.7812 0.0800781C15.1459 0.0800781 15.4957 0.224944 15.7535 0.482806C16.0114 0.740669 16.1562 1.09041 16.1562 1.45508V4.20508H19.5938C19.8673 4.20508 20.1296 4.31373 20.323 4.50712C20.5163 4.70052 20.625 4.96282 20.625 5.23633C20.625 5.50983 20.5163 5.77214 20.323 5.96553C20.1296 6.15893 19.8673 6.26758 19.5938 6.26758H19.0919L18.0702 19.5405C18.0172 20.2313 17.7054 20.8767 17.1971 21.3476C16.6887 21.8184 16.0214 22.08 15.3285 22.0801H6.6715C5.97861 22.08 5.31127 21.8184 4.80294 21.3476C4.29461 20.8767 3.98276 20.2313 3.92975 19.5405L2.90813 6.26758H2.40625C2.13275 6.26758 1.87044 6.15893 1.67705 5.96553C1.48365 5.77214 1.375 5.50983 1.375 5.23633C1.375 4.96282 1.48365 4.70052 1.67705 4.50712C1.87044 4.31373 2.13275 4.20508 2.40625 4.20508H5.84375ZM4.9775 6.26758H17.0225L16.0133 19.3823C16.0001 19.555 15.9223 19.7163 15.7953 19.8341C15.6684 19.9519 15.5017 20.0174 15.3285 20.0176H6.6715C6.49834 20.0174 6.33163 19.9519 6.20468 19.8341C6.07774 19.7163 5.99991 19.555 5.98675 19.3823L4.9775 6.26758Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_14_12572">
                          <rect
                            width="22"
                            height="22"
                            fill="white"
                            transform="translate(0 0.0800781)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              ))}

              <Button
                color="outlineGray"
                className="!w-[170px] !px-8 mt-2.5"
                scale="md"
                type="button"
                onClick={addPrice}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="mr-2"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                  />
                </svg>
                Add a price
              </Button>
            </div>
          </fieldset>
          <fieldset className="mt-5">
            <legend className="mb-4 text-[22px] font-bold text-black xl:whitespace-nowrap">
              Media
            </legend>

            <div className="gap-5 mb-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 max-w-[1000px]">
              {fields
                .filter((f) => f.url)
                .map((field, index) => (
                  <Media
                    className="w-full"
                    key={field.id}
                    index={index}
                    size={field.size}
                    createdAt={field.createdAt}
                    url={field.url}
                    isPrimary={false}
                    filename={field.filename}
                    remove={removeSingle}
                    checked={false}
                    togglePrimary={() => {}}
                    toggleSelect={() => {}}
                  />
                ))}

              {Array.apply(
                null,
                Array(
                  4 - fields.filter((f) => f.url).length < 0
                    ? 0
                    : 4 - fields.filter((f) => f.url).length
                )
              ).map((x, i) => (
                <MediaUploadArea
                  uploadable={i === 0}
                  createMedia={createMedia}
                  fullSize={true}
                  className=""
                />
              ))}
            </div>
          </fieldset>

          {getShowCropModal && (
            <ModalWrapper
              closeOnOverlay={false}
              className="w-[616px] min-h-cs-394 px-0 sm:px-10 xl:px-0"
              open={getShowCropModal}
              setOpen={setShowCropModal}
              onClose={cancelCrop}
            >
              <h3 className="mb-3 text-2xl font-bold text-cs-gray">
                Crop Image
              </h3>

              <ImageCropper
                file={
                  croppingImageHighlight !== -1
                    ? getHighLightCroppingImage(croppingImageHighlight)!.url
                    : fields[croppingImage ?? 0]?.url ?? ""
                }
                stencilAspectRatio={16 / 9}
                onAccept={
                  croppingImageHighlight !== -1 ? cropHighlightImage : cropImage
                }
                onCancel={cancelCrop}
              />
            </ModalWrapper>
          )}

          <PrimaryButton
            type="submit"
            loading={isLoading || uploadingMedia}
            scale="sm"
          >
            {isLoading || uploadingMedia ? (
              <>
                <span className="flex justify-center">
                  <svg
                    className="w-5 h-5 mr-3 animate-spin text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Uploading
                </span>
              </>
            ) : !isEdit ? (
              "Add Event"
            ) : (
              "Save Changes"
            )}
          </PrimaryButton>
          {isSubmitted && !isValid && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}

          {error && "data" in error && (
            <ErrorMessage>
              {error?.data?.message?.replaceAll('"', "") ?? ""}
            </ErrorMessage>
          )}
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AddEvent;

const Media: React.FC<{
  className?: string;
  index: number;
  url: File;
  filename: string;
  size: number;
  createdAt: Date;
  isPrimary: boolean;
  checked: boolean;
  togglePrimary: (index: number) => void;
  remove: (index: number) => void;
  toggleSelect: (index: number) => void;
}> = ({ className, index, url, filename, checked, remove, toggleSelect }) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [urlString, setUrlString] = useState("");

  useEffect(() => {
    (async () => {
      setUrlString((await fileToDataString(url)) as string);
    })();
  }, [url]);
  return (
    <>
      <div className={`flex flex-col ${className ?? ""}`}>
        <div className="relative flex mt-2">
          <div className="absolute flex justify-between w-full p-4">
            <input
              type="checkbox"
              checked={checked}
              onChange={() => toggleSelect(index)}
            />

            <span className="text-sm">
              <button
                className="text-red-700"
                type="button"
                onClick={() => setDeleteModal(true)}
              >
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.90625 4.20508V2.14258H13.0938V4.20508H6.90625ZM4.84375 4.20508V1.45508C4.84375 1.09041 4.98862 0.740669 5.24648 0.482806C5.50434 0.224944 5.85408 0.0800781 6.21875 0.0800781L13.7812 0.0800781C14.1459 0.0800781 14.4957 0.224944 14.7535 0.482806C15.0114 0.740669 15.1562 1.09041 15.1562 1.45508V4.20508H18.5938C18.8673 4.20508 19.1296 4.31373 19.323 4.50712C19.5163 4.70052 19.625 4.96282 19.625 5.23633C19.625 5.50983 19.5163 5.77214 19.323 5.96553C19.1296 6.15893 18.8673 6.26758 18.5938 6.26758H18.0919L17.0702 19.5405C17.0172 20.2313 16.7054 20.8767 16.1971 21.3476C15.6887 21.8184 15.0214 22.08 14.3285 22.0801H5.6715C4.97861 22.08 4.31127 21.8184 3.80294 21.3476C3.29461 20.8767 2.98276 20.2313 2.92975 19.5405L1.90813 6.26758H1.40625C1.13275 6.26758 0.870443 6.15893 0.677046 5.96553C0.483649 5.77214 0.375 5.50983 0.375 5.23633C0.375 4.96282 0.483649 4.70052 0.677046 4.50712C0.870443 4.31373 1.13275 4.20508 1.40625 4.20508H4.84375ZM3.9775 6.26758H16.0225L15.0133 19.3823C15.0001 19.555 14.9223 19.7163 14.7953 19.8341C14.6684 19.9519 14.5017 20.0174 14.3285 20.0176H5.6715C5.49834 20.0174 5.33163 19.9519 5.20468 19.8341C5.07774 19.7163 4.99991 19.555 4.98675 19.3823L3.9775 6.26758Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </span>
          </div>

          <img
            className="w-full aspect-[16/9] object-cover rounded-sm"
            src={urlString}
            alt={filename}
          />
        </div>
      </div>

      <ModalWrapper
        className="w-[500px] px-10 xl:px-0"
        open={deleteModal}
        setOpen={setDeleteModal}
      >
        <h3 className="text-2xl font-bold text-cs-gray">Delete Image?</h3>
        <p className="text-base font-normal my-7 text-cs-gray">
          You are about to delete a image from your gallery.
        </p>
        <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>

        <div className="flex flex-row mt-10">
          <button
            type="button"
            className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
            onClick={() => remove(index)}
          >
            Yes, Delete it
          </button>

          <button
            type="button"
            className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            No, Don't delete it
          </button>
        </div>
      </ModalWrapper>
    </>
  );
};

export function MediaUploadArea({
  uploadable,
  createMedia,
  fullSize = false,
  className = "xl:w-1/2",
}: {
  uploadable: boolean;
  fullSize?: boolean;
  className?: string;
  createMedia: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}) {
  return (
    <div
      className={`flex flex-col w-full ${
        fullSize
          ? className
          : "sm:w-[calc(50%-1.5rem)] md:w-[calc(25%-1.5rem)] [@media(min-width:2400px)]:w-[calc(16.666%-1.5rem)]"
      }`}
    >
      <div className="relative flex flex-col mt-2">
        <div
          className={`flex justify-center items-center text-center px-10 w-full aspect-[16/9] border-2 border-dotted border-[#CFDBD5] cursor-pointer relative ${
            !uploadable ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          {uploadable ? (
            <span className="text-cs-gray text-xs leading-[18px]">
              <p>Drop image here</p>
              <p>Upload image</p>
            </span>
          ) : (
            <span>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="camera-01">
                  <g id="Icon">
                    <path
                      d="M3.19141 11.963C3.19141 11.4864 3.19141 11.2482 3.21129 11.0475C3.40307 9.11187 4.93431 7.58063 6.86993 7.38885C7.07062 7.36897 7.32175 7.36897 7.824 7.36897C8.01753 7.36897 8.1143 7.36897 8.19645 7.36399C9.24555 7.30046 10.1642 6.63832 10.5562 5.66316C10.5869 5.58679 10.6156 5.5007 10.673 5.32853C10.7304 5.15635 10.7591 5.07026 10.7898 4.9939C11.1818 4.01873 12.1005 3.3566 13.1496 3.29306C13.2318 3.28809 13.3225 3.28809 13.504 3.28809H20.0847C20.2662 3.28809 20.3569 3.28809 20.4391 3.29306C21.4882 3.3566 22.4069 4.01873 22.7989 4.9939C22.8296 5.07026 22.8583 5.15635 22.9157 5.32853C22.9731 5.5007 23.0018 5.58679 23.0325 5.66316C23.4245 6.63832 24.3431 7.30046 25.3922 7.36399C25.4744 7.36897 25.5712 7.36897 25.7647 7.36897C26.2669 7.36897 26.5181 7.36897 26.7188 7.38885C28.6544 7.58063 30.1856 9.11187 30.3774 11.0475C30.3973 11.2482 30.3973 11.4864 30.3973 11.963V22.6043C30.3973 24.8898 30.3973 26.0325 29.9525 26.9055C29.5613 27.6733 28.937 28.2976 28.1691 28.6889C27.2961 29.1337 26.1534 29.1337 23.8679 29.1337H9.72082C7.43531 29.1337 6.29256 29.1337 5.41961 28.6889C4.65174 28.2976 4.02744 27.6733 3.6362 26.9055C3.19141 26.0325 3.19141 24.8898 3.19141 22.6043V11.963Z"
                      stroke="#CFDBD5"
                      strokeWidth="2.59653"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.7943 23.0123C19.7994 23.0123 22.2355 20.5763 22.2355 17.5712C22.2355 14.5661 19.7994 12.13 16.7943 12.13C13.7893 12.13 11.3532 14.5661 11.3532 17.5712C11.3532 20.5763 13.7893 23.0123 16.7943 23.0123Z"
                      stroke="#CFDBD5"
                      strokeWidth="2.59653"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </span>
          )}

          <input
            className="absolute inset-0 w-full h-full opacity-0 appearance-none cursor-pointer"
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={createMedia}
          />
        </div>
      </div>
    </div>
  );
}
