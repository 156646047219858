import './App.css';
import Dashboard from './pages/amp/dashboard/Dashboard';
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider
} from "react-router-dom";
import RouteShell from './components/global/RouteShell';
import ProtectedRoute from './components/global/ProtectedRoute';
import Login from './pages/www/Login';
import { RootState, useAppDispatch } from './store';
import PendingApproval from './pages/amp/dashboard/PendingApproval';
import Attractions from './pages/amp/dashboard/Attractions';
import Users from './pages/amp/dashboard/ManageUsers';
import WithNavigationBar from './components/WithNavigationBar';
import ForgottenPassword from './pages/www/ForgottenPassword';
import ResetPassword from './pages/www/ResetPassword';
import NotFound from './pages/NotFound';
import EditUser from './pages/amp/EditUser'
import MyAccount from './pages/amp/MyAccount'
import { useGetSession } from './hooks/useAuth';
import { globalActions } from './store/globalSlice';
import AttractionGeneral from './pages/amp/attractions/AttractionGeneral';
import AttractionDetails from './pages/amp/attractions/AttractionDetails';
import AttractionLocation from './pages/amp/attractions/AttractionLocation';
import AttractionOpeningTimes from './pages/amp/attractions/AttractionOpeningTimes';
import AttractionPrices from './pages/amp/attractions/AttractionPrices';
import AttractionFAQs from './pages/amp/attractions/AttractionFAQs';
import AttractionImages from './pages/amp/attractions/AttractionImages';
import AttractionChangeLog from './pages/amp/attractions/AttractionChangeLog';
import AddUser from './pages/amp/AddUser';
import CreateClaim from './pages/www/CreateClaim';
import SearchClaim from './pages/www/SearchClaim';
import About from './pages/www/About';
import ToastNotification from "./components/global/amp/ToastNotification"
import { useAppSelector } from "./store";
import { ErrorPage, WithPermission } from './components/global/amp/PermissionError';
import VerifyEmail from './pages/www/VerifyEmail';
import AttractionMain from './pages/amp/attractions/AttractionMain';
import TermsOfUse from './pages/amp/TermsOfUse'
import { useEffect } from 'react';
import AttractionPremiumEnhanced from './pages/amp/attractions/AttractionPremiumEnhanced';
import AdPlacements from './pages/amp/dashboard/AdPlacements';
import AddAdPlacement from './pages/amp/AddAdPlacement';
import SkipPayment from './pages/www/SkipPayment';
import PaymentSuccess from './pages/www/PaymentSuccess';
import AttractionTickets from './pages/amp/attractions/AttractionTickets';
import CreateTicket from './pages/amp/ticketing/CreateTicket/CreateTicket';
import ScanTicket from './pages/amp/ticketing/ScanTicket/ScanTicket';
import AvailabilityPeriods from './pages/amp/ticketing/AvailabilityPeriods/AvailabilityPeriods';
import CreateAvailabilityPeriod from './pages/amp/ticketing/AvailabilityPeriods/CreateAvailabilityPeriod';
import AvailabilityPeriodConfiguration from './pages/amp/ticketing/AvailabilityPeriods/AvailabilityPeriodConfiguration';
import AttractionSubscription from './pages/amp/attractions/AttractionSubscription';
import TicketingProducts from './pages/amp/ticketing/TicketingProducts/TicketingProducts';
import CreateTicketingProduct from './pages/amp/ticketing/TicketingProducts/CreateTicketingProduct';
import AvailabilityPeriodPricing from './pages/amp/ticketing/AvailabilityPeriods/AvailabilityPeriodPricing';
import VenueOptScore from './pages/amp/dashboard/VenueOptScore';
import AttractionVenueOptScore from './pages/amp/attractions/AttractionVenueOptScore';
import AttractionPayPublish from './pages/amp/attractions/AttractionPayPublish';
import Events from './pages/amp/dashboard/Events';
import AddEvent from './pages/amp/AddEvent';


declare global {
  interface Window {
    connectFeatureFlags?: Record<'venueOptScore', boolean>;
  }
}

window.connectFeatureFlags = {
  venueOptScore: true
} satisfies Record<string, boolean>

export type RouteKeys = 
        "DASH_HOME" | 
        "PENDING_APPROVAL" |
        "AD_PLACEMENTS" |
        "EVENTS" |
        "ATTRACTIONS" |
        "MANAGE_USERS" |
        "VENUE_OPT_SCORE" |
        "ATTRACTION_GENERAL" |
        "ATTRACTION_DETAILS" |
        "ATTRACTION_LOCATION" |
        "ATTRACTION_OPENING_TIMES" |
        "ATTRACTION_PRICES" |
        "ATTRACTION_FAQS" |
        "ATTRACTION_IMAGES" |
        "ATTRACTION_CHANGE_LOG" |
        "ATTRACTION_PREMIUM_ENHANCED" | 
        "ATTRACTION_UPGRADE_PLAN" |
  "TICKETING" | "ATTRACTION_TICKETS" |
        "ATTRACTION_VENUE_OPT_SCORE" | 
        "ATTRACTION_PAY_PUBLISH"

type TitleReturnType = string | React.ReactElement;
export interface AppRouteObject {
  title: TitleReturnType | ((state: RootState) => TitleReturnType),
  key?: RouteKeys,
  routeObject: RouteObject
}

const appRoutesToRouteObjects = (routes: AppRouteObject[]) => routes.reduce((prev, curr, i) => {
  return [...prev, curr.routeObject];
}, [] as RouteObject[]);

//Map over these to combine navBar and react-router-dom nestings
const dashboardRoutes: AppRouteObject[] = [
  //
  {
    title: "Home",
    key: "DASH_HOME",
    routeObject: {
      index: true,
      element: <Dashboard /> //https://uzfbs4.axshare.com/?id=a1hprx&p=admin_dashboard&sc=1&dp=0&g=1
    },
  },
  {
    title: (state) => "Pending Approval",
    key: "PENDING_APPROVAL",
    routeObject: {
      path: "pending-approval",
      element: <WithPermission type="FORBIDDEN" allowedUsers={["ADMIN"]}><PendingApproval /></WithPermission>
    }
  },
  {
    title: "Attractions",
    key: "ATTRACTIONS",
    routeObject: {
      path: "attractions",
      element: <Attractions />
    }
  },
  {
    title: "Manage Users",
    key: "MANAGE_USERS",
    routeObject: {
      path: "users",
      element: <WithPermission type="FORBIDDEN" allowedUsers={["ADMIN", "OPERATOR_OWNER"]}><Users /></WithPermission>
    }
  },
  {
    title: "Venue Optimisation Score",
    key: "VENUE_OPT_SCORE",
    routeObject: {
      path: "venue-optimisation-score",
      element: <WithPermission type="FORBIDDEN" allowedUsers={["OPERATOR_OWNER", "ATTRACTION_MANAGER", "ADMIN"]}><VenueOptScore /></WithPermission>
    }
  },
  {
    title: (state) => "Ad Placements",
    key: "AD_PLACEMENTS",
    routeObject: {
      path: "ad-placements",
      element: <WithPermission type="FORBIDDEN" allowedUsers={["ADMIN"]}><AdPlacements /></WithPermission>
    }
  },
  {
    title: (state) => "Events",
    key: "EVENTS",
    routeObject: {
      path: "events",
      element: <WithPermission type="FORBIDDEN" allowedUsers={["ADMIN"]}><Events /></WithPermission>
    }
  },
]



//Map over these to combine navBar and react-router-dom nestings
export const attractionRoutes: AppRouteObject[] = [
  //
  {
    title: "Overview",
    key: "ATTRACTION_GENERAL",
    routeObject: {
      path: "overview",
      element: <AttractionGeneral />
    },
  },
  {
    title: "Attraction Details",
    key: "ATTRACTION_DETAILS",
    routeObject: {
      path: "details",
      element: <AttractionDetails showPublishingButtons />
    },
  },
  {
    title: "Location",
    key: "ATTRACTION_LOCATION",
    routeObject: {
      path: "location",
      element: <AttractionLocation showPublishingButtons />
    },
  },
  {
    title: "Opening Times",
    key: "ATTRACTION_OPENING_TIMES",
    routeObject: {
      path: "opening-times",
      element: <AttractionOpeningTimes showPublishingButtons />
    },
  },
  {
    title: "Prices",
    key: "ATTRACTION_PRICES",
    routeObject: {
      path: "prices",
      element: <AttractionPrices showPublishingButtons />
    },
  },
  {
    title: "FAQs",
    key: "ATTRACTION_FAQS",
    routeObject: {
      path: "faqs",
      element: <AttractionFAQs showPublishingButtons />
    },
  },
  {
    title: "Images",
    key: "ATTRACTION_IMAGES",
    routeObject: {
      path: "images",
      element: <AttractionImages showPublishingButtons />
    },
  },
  {
    title: "Payment",
    key: "ATTRACTION_PAY_PUBLISH",
    routeObject: {
      path: "pay-publish",
      element: <AttractionPayPublish showPublishingButtons />
    },
  },
   {
    title: "Change Log",
    key: "ATTRACTION_CHANGE_LOG",
    routeObject: {
      path: "change-log",
      element: <AttractionChangeLog showPublishingButtons />
    },
  },
  {
    title: "Ticketing",
    key: "ATTRACTION_TICKETS",
    routeObject: {
      path: "ticketing",
      element: <AttractionTickets />
    }
  }, {
    title: "Upgrade Now 🚀",
    key: "ATTRACTION_UPGRADE_PLAN",
    routeObject: {
      path: "subscription",
      element: <AttractionSubscription showPublishingButtons />
    },
  },
  {
    title: "Venue Optimisation Score",
    key: "ATTRACTION_VENUE_OPT_SCORE",
    routeObject: {
      path: "venue-optimisation-score",
      element: <AttractionSubscription showPublishingButtons />
    },
  },
]

export const globalRouteObjects: RouteObject[] = [
  {
    path: "/",
    element: <RouteShell isAmp />,
    children: [
      {
        index: true,
        element: <Navigate to={"/dashboard"} replace={true} />
      },
      {
        path: "dashboard",
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /></ProtectedRoute>,
        children: appRoutesToRouteObjects(dashboardRoutes)
      },
      {
        path: "my-account", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><MyAccount /></ProtectedRoute>
      },
      {
        path: "user/:userId", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><EditUser /></ProtectedRoute>
      },
      {
        path: "user/add", //https://uzfbs4.axshare.com/?id=u18980&p=dowtk_admin_-_add_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><AddUser /></ProtectedRoute>
      },
      {
        path: "ad-placements/add", //https://uzfbs4.axshare.com/?id=u18980&p=dowtk_admin_-_add_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><AddAdPlacement /></ProtectedRoute>
      },
      {
        path: "ad-placements/:id", //https://uzfbs4.axshare.com/?id=u18980&p=dowtk_admin_-_add_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><AddAdPlacement isEdit={true} /></ProtectedRoute>
      },

      {
        path: "ticketing/scan",
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><ScanTicket /></ProtectedRoute>
      },

      {
        path: "events/add",
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><AddEvent /></ProtectedRoute>
      },
      {
        path: "events/:id",
        element: <ProtectedRoute><WithNavigationBar routes={dashboardRoutes} /><AddEvent isEdit={true} /></ProtectedRoute>
      },
    ]
  },
  {
    path: "/attractions/:attractionId",
    element: <RouteShell isAmp attractionId={"/attractions/:attractionId"} />,
    children: [
      {
        path: "",
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionMain /></ProtectedRoute>,
      }, 
      {
        path: "overview", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionGeneral/></ProtectedRoute>
      },
      {
        path: "details", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionDetails showPublishingButtons/></ProtectedRoute>,
      },
      {
        path: "location", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionLocation showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "opening-times", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionOpeningTimes showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "prices", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionPrices showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "faqs", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionFAQs showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "images", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionImages showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "change-log", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionChangeLog showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "premium-enhanced", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionPremiumEnhanced showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "ticketing",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionTickets /></ProtectedRoute>
      },
      {
        path: "ticketing/create/:ticketId?",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><CreateTicket /></ProtectedRoute>

      },
      {
        path: "ticketing/availability-periods",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AvailabilityPeriods /></ProtectedRoute>
      },
      {
        path: "ticketing/availability-periods/create/:availabilityPeriodId?",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><CreateAvailabilityPeriod /></ProtectedRoute>

      },
      {
        path: "ticketing/availability-periods/:availabilityPeriodId/configure",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AvailabilityPeriodConfiguration /></ProtectedRoute>
      },
      {
        path: "ticketing/seasonal-pricing/:ticketId",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AvailabilityPeriodPricing /></ProtectedRoute>
      },
      {
        path: "ticketing/products",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><TicketingProducts /></ProtectedRoute>
      },
      {
        path: "ticketing/products/create/:productId?",
        element: <ProtectedRoute><WithNavigationBar showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><CreateTicketingProduct /></ProtectedRoute>
      },
      {
        path: "subscription",
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionSubscription showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "venue-optimisation-score", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionVenueOptScore showPublishingButtons/></ProtectedRoute>
      },
      {
        path: "pay-publish", //https://uzfbs4.axshare.com/?id=0onmae&p=dowtk_admin_-_edit_user&sc=1&dp=0&g=1
        element: <ProtectedRoute><WithNavigationBar isAttraction={true} showExitEditing routes={attractionRoutes} mobileRoutes={dashboardRoutes} /><AttractionPayPublish showPublishingButtons/></ProtectedRoute>
      },
    ]
  },
  {
    path: "/",
    element: <RouteShell isAmp={false} />,
    children: [
      {
        path: "login",
        element: <Login />
      },
      {
        path: "verify-email",
        element: <VerifyEmail />
      },
      {
        path: "forgotten-password",
        element: <ProtectedRoute loggedOutOnly><ForgottenPassword /></ProtectedRoute>
      },
      {
        path: "reset-password",
        element: <ProtectedRoute loggedOutOnly><ResetPassword /></ProtectedRoute>
      },
      {
        path: "create-claim",
        element: <CreateClaim />
      },
      {
        path: "skip-payment",
        element: <SkipPayment />
      },
      {
        path: "payment-success",
        element: <PaymentSuccess />
      },
      {
        path: "search-claim",
        element: <SearchClaim />
      },
      {
        path: "terms-of-service",
        element: <TermsOfUse />
      },
      {
        path: "forbidden",
        element: <ErrorPage type="FORBIDDEN" />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: 'attractions//subscription',
        element: <Navigate to={"/dashboard"} replace={true} />
      }
    ]
  },
  {
    path: "*",
    element: <NotFound />
  }
];

const router = createBrowserRouter(globalRouteObjects);

function App() {

  const dispatch = useAppDispatch();
  const { toastNotification, user } = useAppSelector(state => state.global)

  useGetSession({
    onComplete: () => {
      dispatch(globalActions.setAppLoaded(true));
    }
  });

  useEffect(() => {
    if (user) { //Logged in
      const script = document.createElement("script");
      script.src = "/chatbot.js";
      script.async = true;

      const script2 = document.createElement("script");
      script2.src = "/clarity.js";
      script2.async = true;

      document.head.appendChild(script);
      document.head.appendChild(script2);
      return () => {
        document.head.removeChild(script);
        document.head.removeChild(script2);
      }
    }
  }, [user])

  return (
    <>
      <div className="root-app">
        <RouterProvider router={router} />
      </div>
      <ToastNotification pendingType={toastNotification.pendingType} type={toastNotification.type} message={toastNotification.message} attractionApprovalStatus={toastNotification.attractionApprovalStatus} attractionImage={toastNotification.attractionImage} attractionName={toastNotification.attractionName} attractionAddress={toastNotification.attractionAddress} />
    </>
  );
}

export default App;
