import React, { useEffect, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../../lib/react-table/Table";
import Footer from "../../../components/global/amp/Footer";
import moment from "moment";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { apiSlice } from "../../../store/apiSlice";
import { useAppSelector } from "../../../store";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { Button } from "../../../components/global/Button";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { TableText } from "../../../components/global/TableText";
import { TableTextNormal } from "../../../components/global/TableTextNormal";
import ContextMenuButtons from "../../../components/global/ContextMenuButtons";
import { SearchField } from "../../../components/global/SearchField";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const Events: React.FC = () => {
  const windowSize = useWindowResize();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector((state: any) => state.global);
  const [getFilter, setFilter] = useState<"active" | "future" | "archive">(
    "active"
  );

  const prevFilter = useRef(getFilter);
  useEffect(() => {
    if (prevFilter.current !== getFilter) {
      setQueryPage("0");
      gotoPage(0);

      prevFilter.current = getFilter;
    }
  }, [getFilter]);

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("title");
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");

  const prevQuerySearch = useRef(getQuerySearchQuery);
  const { data: tableData, isFetching } = apiSlice.useGetEventsQuery({
    pageSize: getQueryPageSize,
    filter: getFilter,
    query: getQuerySearchQuery,
    sortBy: getQuerySortBy,
    sortDesc: getQuerySortDesc,
    page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
  });

  const [deleteEvent] = apiSlice.useDeleteEventMutation();

  const data = React.useMemo<any>(() => tableData?.results ?? [], [tableData]);

  const columns = React.useMemo<Column<AmpEvents>[]>(
    () => [
      {
        Header: "Title",
        accessor: "title",
        width: "50%",
        Cell: ({ row: { original } }: CellProps<AmpEvents>) => {
          return (
            <>
              <div className="w-full">
                <TableTextNormal className="w-full">
                  <span className="inline xl:hidden text-xs text-black font-bold mr-2">
                    Title:
                  </span>
                  {original.title || "-"}
                </TableTextNormal>
              </div>
            </>
          );
        },
      },
      {
        Header: "Website",
        accessor: "website",
        width: "10%",
        Cell: ({ row: { original } }: CellProps<AmpEvents>) => {
          return <TableTextNormal className="w-full text-center">
              {original.website || '-'}
          </TableTextNormal>;
        },
      },
      {
        Header: "Venue",
        accessor: "venue_name",
        width: "10%",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<AmpEvents>) => {
          return <TableTextNormal className="w-full text-center">
              {original.venue_name || '-'}
          </TableTextNormal>;
        },
      },
      {
        Header: "Event Type",
        accessor: "event_type",
        width: "10%",
        Cell: ({ row: { original } }: CellProps<AmpEvents>) => {
          return <TableTextNormal className="w-full text-center">
              {original.event_type || '-'}
          </TableTextNormal>;
        },
      },
      {
        Header: "Valid From",
        accessor: "valid_from",
        width: "10%",
        Cell: ({ row: { original } }: CellProps<AmpEvents>) => {
          return <TableTextNormal className="w-full text-center">
              {moment(original.valid_from).format("DD/MMM/YYYY")}
          </TableTextNormal>;
        },
      },
      {
        Header: "Valid To",
        accessor: "valid_to",
        width: "10%",
        Cell: ({ row: { original } }: CellProps<AmpEvents>) => {
          return <TableTextNormal className="w-full text-center">
              {moment(original.valid_to).format("DD/MMM/YYYY")}
          </TableTextNormal>;
        },
      },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<AmpEvents>) => {
          return (
            <ContextMenuButtons
              options={[
                {
                  name: "View / Edit",
                  action: async () => {
                    return navigate(`/events/${original.short_id}`);
                  },
                },
                {
                  name: "Delete",
                  action: async () => {
                    await deleteEvent({ id: original.id });
                  },
                },
              ]}
            />
          );
        },
        disableGlobalFilter: true,
        width: "5%",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<AmpEvents>(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: tableData?.total,
      disableSortRemove: true,
      initialState: {
        hiddenColumns: user?.role !== "ADMIN" ? ["operator", "role"] : [],
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<AmpEvents>;

  useEffect(() => {
    document.title = "Events | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery]);
  useEffect(() => {
    if (!isFetching) {
      setSearchParams(
        {
          pageSize: pageSize.toString(),
          page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");
      setQuerySortBy(sortBy[0].id.toLowerCase());
      setQuerySortDesc(sortBy[0].desc!.toString());
    }
  }, [
    pageSize,
    pageIndex,
    sortBy[0].id,
    globalFilter,
    sortBy[0].desc,
    isFetching,
  ]);

  useEffect(() => {
    if (!user?.email_verified && user?.role !== "ADMIN") {
      navigate("/dashboard");
    }
  }, []);

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <DashboardHeaderContainer slim={true}>
        <div className="flex items-center flex-wrap gap-y-2 w-[calc(100%-28px)] xl:w-full">
          <h1 className="font-extrabold text-xl text-black">Ad Event</h1>

          <SearchField
            parentClassName="max-w-full w-[437px] ml-auto"
            defaultValue={searchParams.get("query") ?? ""}
            onChange={handleFilterInputChange}
            placeholder="Search for title"
          />

          <Link to="/events/add" className="ml-2">
            <Button>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8124 8.66683H8.63533V11.8439C8.63533 12.0124 8.56839 12.1741 8.44923 12.2932C8.33006 12.4124 8.16844 12.4793 7.99992 12.4793C7.8314 12.4793 7.66978 12.4124 7.55061 12.2932C7.43145 12.1741 7.3645 12.0124 7.3645 11.8439V8.66683H4.18742C4.0189 8.66683 3.85728 8.59988 3.73811 8.48072C3.61895 8.36156 3.552 8.19994 3.552 8.03141C3.552 7.86289 3.61895 7.70127 3.73811 7.58211C3.85728 7.46294 4.0189 7.396 4.18742 7.396H7.3645V4.21891C7.3645 4.05039 7.43145 3.88877 7.55061 3.76961C7.66978 3.65044 7.8314 3.5835 7.99992 3.5835C8.16844 3.5835 8.33006 3.65044 8.44923 3.76961C8.56839 3.88877 8.63533 4.05039 8.63533 4.21891V7.396H11.8124C11.9809 7.396 12.1426 7.46294 12.2617 7.58211C12.3809 7.70127 12.4478 7.86289 12.4478 8.03141C12.4478 8.19994 12.3809 8.36156 12.2617 8.48072C12.1426 8.59988 11.9809 8.66683 11.8124 8.66683Z"
                  fill="white"
                />
              </svg>
              Add Event
            </Button>
          </Link>
        </div>
      </DashboardHeaderContainer>

      <DashboardContainer>
        <section className="flex flex-col pt-4 px-5 xl:px-[20px]">
          <section className="flex flex-col px-5 xl:px-[20px]">
            <div id="events" className="mt-5 max-w-[calc(100vw-3rem)]">
              <Table<AmpEvents>
                // <table id>
                tableId="eventsTable"
                // <table className>
                tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
                // <thead className>
                tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl [&:nth-last-child(2)]:rounded-r-xl"
                // <tbody className>
                tableTbodyClassNames={`relative ${
                  page.length === 0 ? "h-[60px]" : ""
                }`}
                // Mobile <tbody className>
                tableTbodyMobileClassNames="block"
                // <tbody><tr className>
                tableTbodyTrClassNames="h-1 last-of-type:hidden"
                // Empty <tbody><tr className>
                tableTbodyTrEmptyMobileClassNames="border-none"
                // Mobile <tbody><tr className>
                tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative border-2 border-cs-off-white font-sm font-normal text-black rounded-xl p-3 bg-white"
                // <tbody><tr><td className>
                tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
                // Empty <tbody><tr><td className>
                tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
                // Mobile <tbody><tr><td className>
                tableTbodyTrEmptyTdMobileClassNames="h-2"
                // Hide Columns On Mobile
                hideColumnOnMobile={[]}
                fullWidthMobileTd={[]}
                spacedMobileTd={[]}
                // react-table props
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                page={page}
                pages={tableData?.total}
                prepareRow={prepareRow}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                setPageSize={setPageSize}
                isFetching={isFetching}
              />
            </div>
          </section>
        </section>
      </DashboardContainer>

      <Footer />
    </div>
  );
};

export default Events;
