import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AmpUserDetail, AmpUserInvitePayload, AmpUserPartial, AmpUserRegisterPayload, AmpUserUpdatePayload } from '../types/user'
import { CreateAdPlacementPayload } from '../pages/amp/AddAdPlacement';
import { VenueOptTask } from '../pages/amp/attractions/AttractionVenueOptScore';


interface CustomError {
    data: {
        message: string,
        stack: string
    },
    status: number
}

export interface ShortData {
    interval_start: string
    interval_end: string
    count: string
}

export interface CreateAttractionArgs {
    userId?: number,
    name: string,
    website: string,
    town_id: string,
    postcode: string,
    phone: string,
    no_phone_number: boolean,
    // listing_type: 'attraction' | 'event' | 'club' | 'festival', 
    duration_type: 'permanent' | 'temporary',
    // start_working?: string,
    // end_working?: string
}

export const apiSlice = createApi({
    reducerPath: "globalApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/amp/v1" }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    tagTypes: ['Pending', 'Attraction', 'Queue', 'AdPlacements', 'Events'],
    endpoints: (builder) => ({
        login: builder.mutation<AmpUserPartial | null, LoginRequest>({
            query: (req) => ({
                url: `sign-in`,
                method: "POST",
                body: req
            })
        }),
        register: builder.mutation<{ id: number, alreadyExist?: boolean } | null, AmpUserRegisterPayload>({
            query: (req) => ({
                url: `sign-up`,
                method: "POST",
                body: req
            })
        }),
        verifyEmail: builder.mutation<null, { userId: string, token: string }>({
            query: (arg) => ({
                url: `verify-email`,
                method: "POST",
                body: arg
            })
        }),
        session: builder.query<AmpUserPartial | null, void>({
            query: () => `session`,
            keepUnusedDataFor: 0
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: `sign-out`,
                method: "POST"
            })
        }),
        passwordResetRequest: builder.mutation<MessageResponse, string>({
            query: (email) => ({
                url: `password-reset-request`,
                method: "POST",
                body: { email },
            }),
        }),
        passwordReset: builder.mutation<MessageResponse, PasswordResetRequest>({
            query: (req) => ({
                url: `password-reset`,
                method: "POST",
                body: req
            })
        }),
        getUsers: builder.query<ObjectionResponse<AmpUserDetail>, ListQuery | void>({
            query: (query) => `users${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getUserByEmail: builder.query<AmpUserDetail, string>({
            query: (email) => `users/email/${email}`,
            keepUnusedDataFor: 0
        }),
        getUserAttractions: builder.query<ObjectionResponse<AttractionDetails>, any>({
            query: (arg) => `/attractions?pageSize=300&sortBy=name${!!arg?.showUnapproved ? "&showUnapproved=true" : ""}${(!!arg?.onlyBasicInformation) ? "&onlyBasicInformation=true" : ""}${(!!arg?.hideBasic) ? `&hideBasic=true` : ''}${arg?.operatorId ? `&operatorId=${arg?.operatorId}` : ""}${arg?.page ? '&page=' + arg?.page : ''}${arg?.query ? '&query=' + arg?.query : ''}`,
            keepUnusedDataFor: 0
        }),
        updateUser: builder.mutation<AmpUserDetail, EntityUpdateRequest<AmpUserUpdatePayload>>({
            query: (arg) => ({
                url: `users/${arg.id}`,
                method: "PATCH",
                body: arg.payload
            })
        }),
        inviteUser: builder.mutation<MessageResponse, AmpUserInvitePayload>({
            query: (body) => ({
                url: `users/invite`,
                method: "POST",
                body
            })
        }),
        sendEmailVerification: builder.mutation<{ message: string }, { userId: string }>({
            query: (body) => ({
                url: `users/verify`,
                method: "POST",
                body
            })
        }),
        getAttraction: builder.query<EntityDetailsResponse<AttractionDetails>, string>({
            query: (query) => `attractions/${query}`,
            keepUnusedDataFor: 0,
            providesTags: (data) => [{ type: 'Attraction', id: data?.result?.short_id }]
        }),
        getAttractionHeader: builder.query<EntityDetailsResponse<AttractionDetails>, string>({
            query: (query) => `attractions/${query}`,
            keepUnusedDataFor: 0,
        }),
        searchAttractions: builder.query<{ results: AttractionDetails[] }, string>({
            query: (query) => `attractions/search${query ? `?query=${query}&pageSize=300` : ''}`,
            keepUnusedDataFor: 0
        }),
        searchSingleAttraction: builder.query<{result: AttractionDetails}, string>({
            query: (query) => `attractions/search${query ? `?attractionId=${query}` : ''}`,
            keepUnusedDataFor: 0
        }),
        getAttractionsList: builder.query<ObjectionResponse<AttractionDetails>, ListQuery | void>({
            query: (query) => `attractions${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getPublishQueueList: builder.query<AttractionDetails[], ListQuery | void>({
            query: (query) => `publish-queues${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0,
            providesTags: ['Queue'],
        }),
        searchTowns: builder.query<ObjectionResponse<TownList>, string>({
            query: (query) => `towns${query ? `?query=${query}` : ''}`,
            keepUnusedDataFor: 0
        }),
        searchCounties: builder.query<ObjectionResponse<TownList>, string>({
            query: (query) => `counties${query ? `?query=${query}` : ''}`,
            keepUnusedDataFor: 0
        }),
        listAttractions: builder.query<ObjectionResponse<AttractionDetails>, ListQuery | { all: boolean } | void>({
            query: (query) => `attractions${query?.all ? '/claims' : ''}`,
            keepUnusedDataFor: 0,
            transformResponse: (response: ObjectionResponse<AttractionClaims>, meta, arg: ListQuery | { all: boolean } | void) => {
                return {
                    results: arg?.all ? response.results.map(r => ({
                        ...r.venue,
                        status: r.status,
                        venue_status: r.venue.status,
                        submit_approval: r.submit_approval,
                        total_vo_score: r.venue.total_vo_score,
                        max_possible_vo_score: r.venue.max_possible_vo_score

                    })) : (response.results as unknown as AttractionDetails[]),
                    total: response.total
                }
            }
        }),
        uploadImageToAttraction: builder.mutation<AttractionMedia, {
            venueId: string,
            file: FormData
        }>({
            query: (arg) => {
                return ({
                    url: `attractions/draft/image/${arg.venueId}`,
                    method: "POST",
                    body: arg.file
                })
            }
        }),
        updateAttractionDraft: builder.mutation<AttractionDetails, EntityUpdateRequest<any> & { publish?: boolean }>({
            query: (arg) => ({
                url: `attractions/draft/${arg.id}${arg.publish ? '?publish=true' : ''}`,
                method: "PATCH",
                body: arg.payload
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        updateAttractionPublish: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/publish/${id}`,
                method: "PATCH"
            })
        }),
        publishStatus: builder.mutation<{ status: boolean }, { id: string, forcePublish: boolean }>({
            query: (args) => ({
                url: `attractions/publish-status/${args.id}${args.forcePublish ? '?forcePublish=true' : ''}`,
                method: "PATCH"
            })
        }),
        doneWalkthrough: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/done-walkthrough/${id}`,
                method: "PATCH"
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        editDoneWalkthrough: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/edit-done-walkthrough/${id}`,
                method: "PATCH"
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        finishedOnboarding: builder.mutation<AttractionDetails, string>({
            query: (id) => ({
                url: `attractions/finished-onboarding/${id}`,
                method: "PATCH"
            }),
            invalidatesTags: (data, error) => error ? [] : [{ type: 'Attraction', id: data?.short_id }]
        }),
        userDoneWalkthrough: builder.mutation<{success: true}, void>({
            query: () => ({
                url: `users/done-walkthrough`,
                method: "PATCH"
            }),
        }),
        getAttractionDraftPreview: builder.query<{ url: string }, string>({
            query: (id) => `attractions/preview/request/${id}`,
            keepUnusedDataFor: 0
        }),
        getAttractionCategories: builder.query<AttractionCategories[], void>({
            query: () => `attractions/categories`,
            keepUnusedDataFor: 0
        }),
        getOperators: builder.query<ObjectionResponse, { query?: string } | void>({
            query: (args) => `operators?pageSize=100&sortBy=title${args?.query ? '&query=' + args.query : ''}`,
            keepUnusedDataFor: 0
        }),
        getPendingApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=1000&pendingApproval=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        getNewApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=1000&new=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        getEventApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=1000&event=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        getDeniedApprovalAttractions: builder.query<ObjectionResponse<AttractionDetails>, AttractionDetails | void>({
            query: () => `attractions/claims?pageSize=100&denied=true`,
            providesTags: ['Pending'],
            keepUnusedDataFor: 0
        }),
        updatePendingApproval: builder.mutation<any, any>({
            query: (arg) => ({
                url: `attractions/resolve-claim/${arg.id}`,
                method: "POST",
                body: arg.payload
            }),
            invalidatesTags: ['Pending']
        }),
        updateSubmitClaim: builder.mutation<any, any>({
            query: (id) => ({
                url: `attractions/submit-claim/${id}`,
                method: "POST",
            }),
            invalidatesTags: ['Pending']
        }),
        submitQueue: builder.mutation<any, any>({
            query: (id) => ({
                url: `attractions/submit-publish/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ['Queue']

        }),
        denyQueue: builder.mutation<any, any>({
            query: (payload) => ({
                url: `attractions/deny-publish/${payload.id}`,
                body: {
                    message: payload.message
                },
                method: "PATCH",
            }),
            invalidatesTags: ['Queue']
        }),
        getVersionNumber: builder.query<{ version: string }, void>({
            query: () => `/meta-data`,
            keepUnusedDataFor: 0
        }),
        createClaim: builder.mutation<{ success: boolean }, { userId: number, attractionId: string }>({
            query: (arg) => ({
                url: `attractions/claim/${arg.attractionId}`,
                method: "POST",
                body: {
                    userId: arg.userId,
                }
            })
        }),
        createAttraction: builder.mutation<AttractionDetails, CreateAttractionArgs>({
            query: (arg) => ({
                url: `attractions`,
                method: "POST",
                body: (() => {
                    const outputObject: Partial<CreateAttractionArgs> = {};
                    for (const key in arg) {
                        if (arg.hasOwnProperty(key) && arg[key as keyof CreateAttractionArgs] !== 'undefined') {
                            // @ts-ignore
                            outputObject[key] = arg[key];
                        }
                    }
                    return outputObject;
                })()
            })
        }),
        getAttractionData: builder.mutation<AttractionsDataResponse, AttractionsDataRequest>({
            query: (arg) => ({
                url: `attractions/data`,
                method: "POST",
                body: arg
            })
        }),
        getAttractionShortData: builder.query<{ created: ShortData[], published: ShortData[], claimed: ShortData[] }, void>({
            query: () => ({
                url: `attractions/short-data`,
                method: "GET",
            })
        }),
        getAttractionChangeLog: builder.query<ObjectionResponse<AttractionChangeLog>, any>({
            query: ({ id, ...query }) => `attraction/${id}/change-log${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getAdPlacements: builder.query<ObjectionResponse<AmpAdPlacements>, ListQuery | void>({
            query: (query) => `ad-placements${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0,
            providesTags: ['AdPlacements'],
        }),
        uploadImageToAdPlacements: builder.mutation<AttractionMedia, { file: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements/draft/image`,
                    method: "POST",
                    body: {
                        base64: arg.file
                    }
                })
            }
        }),
        addPlacement: builder.mutation<CreateAdPlacementPayload & { id: string }, CreateAdPlacementPayload & { id?: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements`,
                    method: "POST",
                    body: arg
                })
            },
            invalidatesTags: ['AdPlacements']
        }),
        deleteAdPlacement: builder.mutation<{}, { id: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements`,
                    method: "DELETE",
                    body: {
                        id: arg.id,
                    }
                })
            },
            invalidatesTags: ['AdPlacements']
        }),
        getPlacement: builder.query<AmpAdPlacements, { id: string }>({
            query: (arg) => {
                return ({
                    url: `ad-placements/${arg.id}`,
                    method: "GET",
                })
            },
        }),
        getLatestUnpaidAttraction: builder.query<AttractionDetails, void>({
            query: () => {
                return ({
                    url: `attractions/latest-unpaid`,
                    method: "GET",
                })
            },
        }),
        createPaymentUrl: builder.mutation<{ sessionUrl: string }, { id: string, planId: string, submit_approval?: string, isOnboarding?: boolean }>({
            query: (arg) => {
                return ({
                    url: `attractions/create-payment/${arg.id}`,
                    method: "POST",
                    body: {
                        submit_approval: arg.submit_approval,
                        planId: arg.planId,
                        isOnboarding: arg.isOnboarding

                    }
                })
            }
        }),
        subscriptionCheckout: builder.mutation<{ sessionUrl: string }, { venueId: string, planId: string, submit_approval?: string }>({
            query: (arg) => {
                return ({
                    url: `subscription/checkout`,
                    method: "POST",
                    body: {
                        submit_approval: arg.submit_approval,
                        planId: arg.planId,
                        venueId: arg.venueId

                    }
                })
            }
        }),
        getTicketingProductList: builder.query<ObjectionResponse<DOWTKTicketingProduct>, ListQuery | void>({
            query: (query) => `tickets/products${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getTicketingProduct: builder.query<DOWTKTicketingProduct, string>({
            query: (ticketingProductId) => `tickets/products/${ticketingProductId}`,
            keepUnusedDataFor: 0,
        }),
        createTicketingProduct: builder.mutation<{ success: boolean }, DOWTKTicketingProduct>({
            query: (body) => ({
                url: `tickets/products`,
                method: "POST",
                body
            })
        }),
        updateTicketingProduct: builder.mutation<DOWTKTicketingProduct, DOWTKTicketingProduct>({
            query: (arg) => ({
                url: `tickets/products/${arg.id}`,
                method: "PATCH",
                body: arg
            }),
        }),
        getTicketList: builder.query<ObjectionResponse<TicketDetails>, ListQuery | void>({
            query: (query) => `tickets${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        updateTicket: builder.mutation<TicketDetails, TicketDetails>({
            query: (arg) => ({
                url: `tickets/${arg.id}`,
                method: "PATCH",
                body: arg
            }),
        }),
        createTicket: builder.mutation<{ success: boolean }, TicketDetails>({
            query: (body) => ({
                url: `tickets`,
                method: "POST",
                body
            })
        }),
        getTicket: builder.query<DOWTKTicket, string>({
            query: (ticketId) => `tickets/${ticketId}`,
            keepUnusedDataFor: 0,
        }),
        getAvailabilityPeriodList: builder.query<ObjectionResponse<DOWTKTicketingAvailabilityPeriod>, ListQuery | void>({
            query: (query) => `tickets/availability-periods${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0
        }),
        getAvailabilityPeriod: builder.query<DOWTKTicketingAvailabilityPeriod, string>({
            query: (availabilityPeriodId) => `tickets/availability-periods/${availabilityPeriodId}`,
            keepUnusedDataFor: 0,
        }),
        createAvailabilityPeriod: builder.mutation<{ success: boolean }, DOWTKTicketingAvailabilityPeriod>({
            query: (body) => ({
                url: `tickets/availability-periods/create`,
                method: "POST",
                body
            })
        }),
        updateAvailabilityPeriod: builder.mutation<DOWTKTicketingAvailabilityPeriod, DOWTKTicketingAvailabilityPeriod>({
            query: (arg) => ({
                url: `tickets/availability-periods/${arg.id}`,
                method: "PATCH",
                body: arg
            }),
        }),
        createAvailabilityTimeslots: builder.mutation<{ success: boolean }, { availabilityPeriodId: string, timeslots: DOWTKTicketingAvailabilityTimeSlot[] }>({
            query: ({ availabilityPeriodId, timeslots }) => ({
                url: `tickets/availability-periods/${availabilityPeriodId}/timeslots`,
                method: "POST",
                body: { timeslots }
            })
        }),
        updateAvailabilityTimeslot: builder.mutation<{ success: boolean }, { availabilityPeriodId: string, timeslot: Partial<DOWTKTicketingAvailabilityTimeSlot> }>({
            query: ({ availabilityPeriodId, timeslot }) => ({
                url: `tickets/availability-periods/${availabilityPeriodId}/timeslots/${timeslot.id}`,
                method: "PATCH",
                body: { timeslot }
            })
        }),
        createAvailabilityPeriodTicketPricing: builder.mutation<DOWTKAvailabilityPeriodTicketPricing, DOWTKAvailabilityPeriodTicketPricing>({
            query: (body) => ({
                url: `tickets/availability-periods/pricing/create`,
                method: "POST",
                body
            })
        }),
        updateAvailabilityPeriodTicketPricing: builder.mutation<DOWTKAvailabilityPeriodTicketPricing, DOWTKAvailabilityPeriodTicketPricing>({
            query: (body) => ({
                url: `tickets/availability-periods/pricing/${body.id}`,
                method: "PATCH",
                body
            })
        }),
        getAvailabilityPeriodTicketPricing: builder.mutation<DOWTKAvailabilityPeriodTicketPricing[], { ticketId: string, availabilityPeriodId: string }>({
            query: ({ ticketId, availabilityPeriodId }) => `tickets/availability-periods/${availabilityPeriodId}/pricing/${ticketId}`,
        }),
        getReservationByQR: builder.mutation<any, string>({
            query: (qr) => `reservation/by-qrcode/${qr}`,
        }),
        getReservationByReference: builder.mutation<any, string>({
            query: (reference) => `reservation/by-reference/${reference}`,
        }),
        checkInReservation: builder.mutation<any, string>({
            query: (reservationID) => ({
                url: `reservation/check-in/${reservationID}`,
                method: "POST",
            }),
        }),
        completePayment: builder.mutation<void, { cbHostedPageId: string }>({
            query: (arg) => {
                return ({
                    url: `attractions/complete-payment/${arg.cbHostedPageId}`,
                    method: "POST",
                })
            }
        }),

        getSubscriptionPlans: builder.query<SubscriptionPlan[], { venueId?: string }>({
            query: (arg) => {
                return ({
                    url: `/subscription/plans${arg.venueId ? `?venueId=${arg.venueId}` : ''}`,
                    method: "GET",
                })
            }
        }),

        getVenueOptScoreMetrics: builder.query<{result: VenueOptTask[]}, void>({
            query: () => {
                return ({
                    url: `attractions/venue-opt-score-metrics`,
                    method: 'GET'
                })
            }
        }),


        getEvents: builder.query<ObjectionResponse<CreateAmpEventsPayload>, ListQuery | void>({
            query: (query) => `events${query ? `?${new URLSearchParams(query).toString()}` : ""}`,
            keepUnusedDataFor: 0,
            providesTags: ['Events'],
        }),
       addEvent: builder.mutation<CreateAmpEventsPayload & { id: string }, CreateAmpEventsPayload & { id?: string }>({
            query: (arg) => {
                return ({
                    url: `events`,
                    method: "POST",
                    body: arg
                })
            },
            invalidatesTags: ['Events']
        }),
        deleteEvent: builder.mutation<{}, { id: string }>({
            query: (arg) => {
                return ({
                    url: `events`,
                    method: "DELETE",
                    body: {
                        id: arg.id,
                    }
                })
            },
            invalidatesTags: ['Events']
        }),
        getEvent: builder.query<AmpEvents, { id: string }>({
            query: (arg) => {
                return ({
                    url: `events/${arg.id}`,
                    method: "GET",
                })
            },
        }),
        uploadImageToEvent: builder.mutation<AttractionMedia, {
            file: FormData
        }>({
            query: (arg) => {
                return ({
                    url: `events/draft/image`,
                    method: "POST",
                    body: arg.file
                })
            }
        }),
    })
})